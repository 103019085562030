import React from 'react';
import { LoadingRing, RipplesButtons } from '..';
import ButtonStyled from './styled';
type Props = {
  children: JSX.Element | JSX.Element[] | string,
  leftIcon?: JSX.Element | JSX.Element[] | string,
  RightIcon?: JSX.Element | JSX.Element[] | string,
  onClick?: (ev?: React.MouseEvent<HTMLDivElement>) => void,
  margin?: string,
  minWidth?: string,
  width?: string,
  color?: string,
  disabled?: boolean,
  circular?: boolean,
  variant?: string,
  padding?: string,
  spacing?: string,
  className?: string,
  loading?: boolean,
  loadingColor?: string,
  fullWidth?: boolean,
  fontSize?: string,
  type?: 'button' | 'submit' | 'reset' | undefined
};
const Button : React.FC<Props> = ({ onClick, children, fontSize, minWidth, loadingColor, margin, width, fullWidth, variant, color, disabled, circular, padding, leftIcon, RightIcon, spacing, className, loading, type } : Props) => {

	return (
		<RipplesButtons style={{
			borderRadius: variant && variant.includes('round') ? '18px' : undefined
		}} className={className} circular={circular} disabled={disabled || loading} minWidth={minWidth} margin={margin} width={fullWidth? '100%' : width} onClick={(!disabled && !loading && onClick) ? (e)=>onClick(e) : undefined}>
			<ButtonStyled fontSize={fontSize} type={type} className={className} spacing={leftIcon && !spacing ? '5px': spacing} padding={loading ? '0px' : padding} circular={circular} disabled={disabled} color={color} variant={variant}>
				{
					loading ? <LoadingRing color={loadingColor ? loadingColor : variant?.includes('underline') ? 'primary' : 'white'} /> : <>
						{leftIcon}<span>{children}</span>{RightIcon}</>
				}
				
			</ButtonStyled>
		</RipplesButtons>
	);
};
export default (Button);