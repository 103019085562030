import styled from 'styled-components';

export interface NivelesCardContainerProps {
  padding?: string;
  last?:boolean;
}

export const NivelesCardContainer = styled.div<NivelesCardContainerProps>`
    border: 1px solid ${props => props.theme.colors.disabledTextFieldGrey};
    background-color: ${props => props.theme.colors.white};
    display: flex;
    border-radius: 8px;
    box-sizing: border-box;
    cursor: pointer;
    overflow: hidden;
    position: relative;
    padding: 5px;
    flex: 1;
    max-height: 80px;
    justify-content: ${props => props.last ? 'center' : null};
    @media (max-width: 824px) {
      flex: 40%;
      min-height: 80px;
    }
    .fueraDeHorarioTag {
      position: absolute;
      left: 0;
      right: 0;
      z-index: 2;
      bottom: -1px;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      width: 100%;
    }
    
`;