import 'react-phone-number-input/style.css';
import React from 'react';
import { CheckIcon, EditIcon, PriorityHighIcon } from '~/assets/icons';
import Typography from '../Typography';
import { TextFieldContainer, TextFieldLabel, TextFieldContainerInput, IconContainer } from './styled';
import PhoneInput from 'react-phone-number-input';
import Grid from '../Grid';
import { useEdit } from '~/hooks';

interface Props extends React.ComponentProps<typeof PhoneInput> {
	label?: string,
	value?: string,
	disabled?: boolean,
	padding?: string,
	helperText?: string,
	error?: boolean,
	check?: boolean,
	edit?: boolean,
	fullWidth?: boolean,
	width?: string
	onChange: (value?: any) => void,
	numberInputProps?: React.ComponentProps<'input'>
}

export type CountryCode = NonNullable<React.ComponentProps<typeof PhoneInput>['defaultCountry']>;

const TextFieldPhoneNumber : React.FC<Props> = ({ 
	padding, 
	label, 
	value, 
	onChange,
	error, 
	check, 
	helperText, 
	edit, 
	disabled, 
	fullWidth, 
	width, 
	limitMaxLength = true,
	defaultCountry = 'MX',
	countryCallingCodeEditable = false,
	international = true,
	...phoneInputProps 
} : Props) => {
	const icon = error || check;

	const {
		editValue,
		handleEditOnChange
	} = useEdit(onChange, edit);

	return (
		<TextFieldContainer width={width} fullWidth={fullWidth} padding={padding}>
			<TextFieldLabel>{label}</TextFieldLabel>
			<TextFieldContainerInput disabled={disabled} error={error} check={check  && !editValue} >
				<Grid item flex>
					<PhoneInput 
						international={international}
						limitMaxLength={limitMaxLength}
						defaultCountry={defaultCountry}
						disabled={disabled}
						value={value}
						countryCallingCodeEditable={countryCallingCodeEditable}
						onChange={editValue ? handleEditOnChange : onChange}
						{...phoneInputProps}
					/>
				</Grid>

				{
					icon && <IconContainer >
						{error ? <PriorityHighIcon color='red' /> : editValue ? <EditIcon /> :  <CheckIcon color='green' />}
					</IconContainer>
				}
			</TextFieldContainerInput>
			<Typography padding='5px 0 0 0' variant='caption' color={error ? 'red' : 'lightGrey'}>{helperText}</Typography>
		</TextFieldContainer>
	);
};
export default (TextFieldPhoneNumber);