import _, { isNumber } from 'lodash';
import moment from 'moment';
import isPropValid from '@emotion/is-prop-valid';
import { ShouldForwardProp, css } from 'styled-components';
import { TIPO_EXTRA_EXTRAS, TIPO_EXTRA_MODIFICADORES, TIPO_EXTRA_VARIANTE } from '~/constants';
import { ConfiguracionProducto, DataCarritoTypes, ErrorTextFieldType, ExtrasTypes, IBannerData } from '~/interfaces';

export const handlePush = (event: any) => {
	// stop the browser from changing the URL and requesting the new document
	event.preventDefault();
	// push an entry into the browser history stack and change the URL
	window.history.pushState({}, '', '/contact');
};

export const getIsTouchDevice = () => {
	// Verifica si la pantalla es touch
	if (typeof window !== 'undefined') {
		const isTablet = window.matchMedia('(pointer: coarse)').matches;
		return ('ontouchstart' in window || isTablet);
	}
	return 0;
};

export const handleEnter = (e: React.KeyboardEvent<HTMLInputElement>, enterFunction: () => void, ready: boolean) => {
	// Valida en ciertos textfields cuando hay enter
	if (e.key === 'Enter' && enterFunction && ready) {
		enterFunction();
	}
};

export const getBase64Images = (img: string) => {
	// refleja imágenes base64
	return `data:image/jpeg;base64, ${img}`;
};

export const pricePrettify = (x: number | string) => {
	// Te regresa un número en formato precio
	x = parseFloat(`${x}`).toFixed(2).toString();
	const pattern = /(-?\d+)(\d{3})/;
	while (pattern.test(x)) {
		x = x.replace(pattern, '$1,$2');
	}
	return `$${x}${x.includes('.') ? '' : '.00'}`;
};

export const getDetalleProductoDisabledButton = (cantidad: number, dataProducto: ConfiguracionProducto, edad: number | string, errorNombreDelFestejado: ErrorTextFieldType, errorNombreCumpleaniero: ErrorTextFieldType,errorComentario: ErrorTextFieldType, nombreCumpleaniero: string, errorPanaBoxRemitente: ErrorTextFieldType, panaBoxRemitente: string, errorPanaBoxDestinatario: ErrorTextFieldType, panaBoxDestinatario: string) => {
	
	let invalidValidation = false;

	dataProducto.dataModificadoresProducto?.forEach(o => {
		const modificadoresSelected = _.filter(o.jModificadores, a=> a.checked === true).length;

		if((o.nCantidadMaxima > 1 || o.nCantidadMinima > 1) ? (modificadoresSelected > 0 && (
			o.nCantidadMinima === 0 ? (modificadoresSelected > o.nCantidadMaxima) : (modificadoresSelected > o.nCantidadMaxima || modificadoresSelected < o.nCantidadMinima)
		)) : false ){			
			invalidValidation = true;
		}
	});

	if (dataProducto.producto.bPanaBox && invalidValidation === false) {
		invalidValidation = panaBoxDestinatario.trim() === '' || panaBoxRemitente.trim() === '';
	}

	// Valida si el botón de get detalle no está listo para añadirlo al carrito
	const dataModificadoresProductoSelected = _.filter(dataProducto.dataModificadoresProducto, o => o.bObligatorio ? (
		_.filter(o.jModificadores, a=> a.checked === true).length > 0 || o.checked
	) : (false));

	const variantesObligatorias = _.filter(dataProducto.dataModificadoresProducto, m=>m.bObligatorio);

	const variantesChecked = _.filter(dataProducto.dataVariantesProducto, o => o.checked !== undefined && o.checkedTipoVariante !== undefined).length;
	return (dataModificadoresProductoSelected.length !== variantesObligatorias.length || cantidad < 0 || cantidad > 15 || (dataProducto.dataVariantesProducto !== undefined && variantesChecked !== dataProducto.dataVariantesProducto.length) || (!dataProducto.producto.nProductoSIP)) || invalidValidation || !cantidad || (dataProducto.producto?.bEdadCumpleanios && !isNumber(edad)) || errorNombreDelFestejado.error || errorPanaBoxDestinatario.error || errorPanaBoxRemitente.error || errorNombreCumpleaniero.error || errorComentario.error || (dataProducto.producto.bNombreCumpleanios && nombreCumpleaniero === '');
};

// 1 Variantes, 2 Modificadores, 3 Extras, 4 Especial
// convierte la data del carrito para poder mapearla en la vista del carrito (Ayuda a validar)
export const getBodyModificaCarrito = (dataProducto?: ConfiguracionProducto | undefined, carrito?: ExtrasTypes[]) => {
	const helperExtras: ExtrasTypes[] = [];
	
	if(carrito){
		carrito.forEach(element => {
			helperExtras.push({
				cDescripcion: element.cDescripcion,
				nCantidad: element.nCantidad || null,
				nExtra: element.nExtra,
				nTipoExtra: element.nTipoExtra,
				nTipoOpcionExtra: element.nTipoOpcionExtra,
			});
		});
	} else if (dataProducto) {
		dataProducto.dataModificadoresProducto.forEach((element) => {
			element.jModificadores.forEach(item => {
				if((element.checked === item.nOpcion && element.checkedTipoModificador === element.nTipoModificador) || item.checked){
					helperExtras.push({
						cDescripcion: item.cDescripcion,
						nCantidad: null,
						nExtra: item.nTipoModificador,
						nTipoExtra: TIPO_EXTRA_MODIFICADORES,
						nTipoOpcionExtra: item.nOpcion,
					});
				}
			});
		});
		dataProducto.dataVariantesProducto.forEach((element) => {
			element.jVariantes.forEach(item => {
				if((element.checked === item.nOpcion && element.checkedTipoVariante === element.nTipoVariante)){
					helperExtras.push({
						cDescripcion: item.cDescripcion,
						nCantidad: null,
						nExtra: item.nTipoVariante,
						nTipoExtra: TIPO_EXTRA_VARIANTE,
						nTipoOpcionExtra: item.nOpcion,
					});
				}
			});
		});
		(dataProducto.dataExtras).forEach(element => {
			if(element.nCantidad)
				helperExtras.push({
					cDescripcion: element.cDescripcion,
					nCantidad: element.nCantidad || null,
					nExtra: element.nExtra,
					nTipoExtra: TIPO_EXTRA_EXTRAS,
					nTipoOpcionExtra: 0,
				});
		});
	}

	return helperExtras;
};

export const handleGetnPedido = (menuId: number | undefined, nVProducto: number, nProductoSIP: number | null, dataCarrito: DataCarritoTypes[] | undefined, helperExtras: ExtrasTypes[]) => {
// busca entre la variación del pedido seleccionado el nProductoSip
	const helperCarritoByMenuId = _.filter(dataCarrito, (o) => o.nValor === menuId);

	if(helperCarritoByMenuId[0] && menuId){
		const helper = _.filter(helperCarritoByMenuId[0].carrito, o=> (o.nVProducto === nVProducto && o.nProductoSIP === nProductoSIP && _.isEqual(o.jExtras, helperExtras)))[0];
		return helper ? helper.nPedido : undefined;
	} else {
		return undefined;
	}
};

export const handleGetHours = () => {
	// Te regresa un arreglo de horas disponibles
	const helper = [
		{
			nValor: 0,
			cValor: 'Entregar ahora'
		}
	];
	let i = 1;
	let hora = moment('09:00','HH:mm').format('HH:mm');

	while (hora !== '19:30') {
		helper.push({
			nValor: i,
			cValor: hora,
		});

		i= i + 1;
		hora = moment(hora, 'HH:mm').add(15, 'minutes').format('HH:mm');
	}

	return helper; 
};

// Rellena columnas containers para que no se vea feo con el space-between
export const getEmptyColumns = (total: number, columns: number) => {
	const helper = [];
	for (let index = 0; index < (columns - total); index++) {
		helper.push(index);
	}
	
	return helper;
};

// Regresa el precio total en el ver detalle del producto
// En el botón agregar.
export const getTotalPrice = (dataProducto: ConfiguracionProducto, cantidad: number) => {
	let helper = dataProducto?.producto.nNuevoPrecio ? (dataProducto?.producto.nNuevoPrecio) : dataProducto?.producto.nPrecio_Base? (dataProducto?.producto.nPrecio_Base) : 0;

	dataProducto.dataExtras.forEach(element => {
		if(element.nCantidad){
			helper = helper + (element.nCantidad * element.nPrecio_Base);
		}
	});

	return pricePrettify(helper * ( cantidad || 1));
};

export const getTotalPriceModificadores = (dataProducto: ConfiguracionProducto, cantidad: number) => {
	let helper = dataProducto?.producto.nNuevoPrecio ? (dataProducto?.producto.nNuevoPrecio) : dataProducto?.producto.nPrecio_Base? (dataProducto?.producto.nPrecio_Base) : 0;

	dataProducto.dataExtras.forEach(element => {
		if(element.nCantidad){
			helper = helper + (element.nCantidad * element.nPrecio_Base);
		}
	});

	dataProducto.dataModificadoresProducto.forEach(modi => {
		if(modi.checked){
			const checkedItem = _.find(modi.jModificadores, o=>o.nOpcion === modi.checked);
			if(checkedItem){
				helper = helper + (checkedItem.nPrecio_Base || 0);
			}
		} else {
			modi.jModificadores.forEach(element => {
				if(element.checked){
					helper = helper + (element.nPrecio_Base || 0);
				}
			});
		}
	});

	dataProducto?.dataComplementos?.forEach(element => {
		if(element.nCantidad){
			helper = helper + (element.nCantidad * element.nPrecio_Base);
		}
	});

	return pricePrettify(helper * ( cantidad || 1));
};

export const handlePhoneFormat = (str: string) => {
	return `${str.substring(0, 3)} ${str.substring(3, 6)} ${str.substring(6, 9)} ${str.substring(9, 13)}`;
};

export const getUtcDate = (dateValue: string, format = 'YYYY-MM-DD') => {
	return moment(dateValue).utc().format(format);
};

export const getBanners = (bannersData: IBannerData[], xs: boolean) => {
	const newBanners:(IBannerData[])[] = [];

	if(xs){
		for (let index = 1; index <= bannersData.length; index= index + 2) {
			newBanners.push(
				[
					bannersData[index-1],
					bannersData[bannersData[index] ? index : 0]
				]
			);
		}
	} else {
		bannersData.forEach(element => {
			newBanners.push(
				[
					element
				]
			);
		});
	}

	return newBanners;
};

const cdnUrl = import.meta.env.VITE_CDN_URL;
export const getCdnUrl = (cImagenUrl='') => {
	return `${cdnUrl}/api/APPWEB/Uploads?file=${cImagenUrl}`;
};

export const handleGoToFacturacion = () => {
	const exLink = import.meta.env.VITE_FACTURACION_LINK;
	window.open(exLink);
};

/* Ya no se usa porque fue reemplazada con la vista dinamicas */
export const handleGoToSorteos = () => {
	const exLink = import.meta.env.VITE_SORTEOS_LINK;
	window.open(exLink);
};

export const handleGoToProveedores = () => {
	const exLink = import.meta.env.VITE_PROVEEDORES_LINK;
	window.open(exLink);
};

export const handleGoToTikTok = () => {
	const exLink = import.meta.env.VITE_TIKTOK_LINK;
	window.open(exLink);
};
export const handleGoToFb = () => {
	const exLink = import.meta.env.VITE_FACEBOOK_LINK;
	window.open(exLink);
};
export const handleGoToInsta = () => {
	const exLink = import.meta.env.VITE_INSTAGRAM_LINK;
	window.open(exLink);
};

export const handleGoToAvisoCln = () => {
	const exLink = import.meta.env.VITE_AVISO_CLN_LINK;
	window.open(exLink);
};
export const handleGoToAvisoMaza = () => {
	const exLink = import.meta.env.VITE_AVISO_MAZA_LINK;
	window.open(exLink);
};

export const handleGoToVacantesDisponibles = () => {
	const exLink = import.meta.env.VITE_VACANTES_DISPONIBLES;
	window.open(exLink);
};

export const handleGoToPanaNews = () => {
	const exLink = 'https://pananews.panama.com.mx/panama';
	window.open(exLink);
};

export const formatPhoneNumber = (phoneNumber: string) => {
	if(phoneNumber?.length === 10){
		const cleanNumber = _.trim(_.replace(phoneNumber, /[^0-9]/g, '')); // Eliminar todos los caracteres que no sean dígitos
		const areaCode = cleanNumber.slice(0, 3); // Obtener el código de área
		const firstPart = cleanNumber.slice(3, 6); // Obtener la primera parte del número
		const secondPart = cleanNumber.slice(6, 10); // Obtener la segunda parte del número

		const formattedNumber = `(${areaCode}) ${firstPart}-${secondPart}`;
		return formattedNumber;

	} else {
		return phoneNumber;
	}
};

export const handleUrlClick = (cUrlClick?: string) => {
	if(!cUrlClick || !window) {
		return;
	}
	window.location.href = cUrlClick;
};

// This implements the default behavior from styled-components v5
export const shouldForwardProp: ShouldForwardProp<'web'> = (propName, target) => {
	if (typeof target === 'string') {
		// For HTML elements, forward the prop if it is a valid HTML attribute
		return isPropValid(propName);
	}
	// For other elements, forward all props
	return true;
};

export const getTextVariant = (props: any) => {
	switch (props.variant) {
	case 'subtitle1':
		return css`
			font-weight: ${props.theme.text.weight.semiBold};
			font-size: ${props.theme.text.size.subtitle1}; //16px
		`;
	case 'subtitle1regular':
		return css`
			font-weight: ${props.theme.text.weight.regular};
			font-size: ${props.theme.text.size.subtitle1};
		`;
	case 'subtitle2regular':
		return css`
			font-weight: ${props.theme.text.weight.regular};
			font-size: ${props.theme.text.size.subtitle2}; //14px
		`;
	case 'subtitle1bold':
		return css`
			font-weight: ${props.theme.text.weight.bold};
			font-size: ${props.theme.text.size.subtitle1};
		`;
	case 'regular':
		return css`
			font-size: ${props.theme.text.size.subtitle2};
		`;
	case 'subtitle2':
		return css`
			font-weight: ${props.theme.text.weight.semiBold};
			font-size: ${props.theme.text.size.subtitle2};
		`;
	case 'subtitle3':
		return css`
			font-weight: ${props.theme.text.weight.semiBold};
			font-size: ${props.theme.text.size.subtitle3};
		`;
	case 'subtitle3regular':
		return css`
			font-weight: ${props.theme.text.weight.regular};
			font-size: ${props.theme.text.size.subtitle3};
		`;
	case 'body1':
		return css`
			font-size: ${props.theme.text.size.subtitle1};
			font-weight: ${props.theme.text.weight.medium};
		`;
	case 'body2':
		return css`
			font-size: ${props.theme.text.size.subtitle2};
			font-weight: ${props.theme.text.weight.medium};
		`;
	case 'body3':
		return css`
			font-size: ${props.theme.text.size.subtitle2};
			font-weight: ${props.theme.text.weight.bold};
		`;
	case 'button':
		return css`
			font-size: ${props.theme.text.size.subtitle1};
			font-weight: ${props.theme.text.weight.medium};
		`;
	case 'caption':
		return css`
			font-size: ${props.theme.text.size.caption};
			font-weight: ${props.theme.text.weight.medium};
		`;
	case 'captionBold':
		return css`
			font-size: ${props.theme.text.size.caption};
			font-weight: ${props.theme.text.weight.bold};
		`;
	case 'overline':
		return css`
			font-size: ${props.theme.text.size.overline};
			font-weight: ${props.theme.text.weight.regular};
		`;
	case 'underline':
		return css`
			text-decoration: underline;
			text-decoration-color: ${props.theme.colors[props.color ? props.color : 'primary']};
			color: ${props.theme.colors[props.color ? props.color : 'primary']};
			font-size: ${props.theme.text.size.subtitle2};
			font-weight: ${props.theme.text.weight.medium};
		`;
	case 'line':
		return css`
			font-size: ${props.theme.text.size.subtitle2};
			font-weight: ${props.theme.text.weight.regular};
			text-decoration: line-through;
		`;
	case 'lineSubtitle1':
		return css`
			font-size: ${props.theme.text.size.subtitle1};
			font-weight: ${props.theme.text.weight.regular};
			text-decoration: line-through;
		`;
	case 'lineCaption':
		return css`
			font-size: ${props.theme.text.size.caption};
			font-weight: ${props.theme.text.weight.regular};
			text-decoration: line-through;
		`;
	case 'body2light':
		return css`
			font-size: ${props.theme.text.size.subtitle2};
			font-weight: ${props.theme.text.weight.light};
		`;
	case 'mini':
		return css`
			font-size: ${props.theme.text.size.overline};
			font-weight: ${props.theme.text.weight.bold};
		`;
	default:
		return css`
			font-weight: ${props.theme.text.weight.semiBold};
			font-size: ${props.theme.text.size.subtitle1};
		`;
	}
};

export const evitarTeclasNumber: React.KeyboardEventHandler<HTMLInputElement> = (event) => {
	// Obtiene el código de la tecla presionada
	const { key } = event;

	const invalidKeys = ['e','E','-','+','.'];

	if (invalidKeys.includes(key)) {
		event?.preventDefault(); // Previene la entrada de la teclas invalidas
	}
};

export const evitarWheel: React.WheelEventHandler<HTMLInputElement> = (e) => {
	e.currentTarget.blur();
};

export const findEarliestDate = (datesStrings: string[]) => {
	const dates = datesStrings.map((dateString) => new Date(dateString));
	// Convierte las fechas a objetos Date y encuentra la fecha mínima
	const earliestDate = new Date(
		Math.min(...dates.map((date) => date.getTime()))
	);

	// Devuelve la fecha en formato 'YYYY-MM-DD'
	return earliestDate.toISOString().split('T')[0];
};

export const maskPhoneNumber = (phoneNumber: string): string => {
	return '*'.repeat(8) + phoneNumber.slice(-2);
};


export const numberInputHelpers = {
	onKeyDown: evitarTeclasNumber,
	onWheel: evitarWheel,
	type: 'number'
};

export {
	getCookiesPreferences,
	updateConsent,
	helperCartEvents,
	helperSearchEvent,
	helperViewItemList,
	helperViewItem,
	helperSelectItem,
	helperBeginCheckout,
	helperPurchase
} from './GoogleTagManager';