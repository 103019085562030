import { Button, Grid, RipplesButtons, Title, Typography } from '~/components';
import { LoginButton } from '../../styled';
import GoogleIcon from '~/assets/icons/googleIcon.webp';

interface props {
	handleAuthGoogle: VoidFunction
	handlePreviousStep: VoidFunction
}

export const CuentaGoogleDetectada = ({
	handleAuthGoogle,
	handlePreviousStep,
} : props) => {
	return (
		<Grid container direction='column'>
			<Title variant={'h5'} margin='0 0 24px 0'>¡Hola de nuevo!</Title>

			<Typography variant='captionBold'>Inicia sesión en tu cuenta:</Typography>
			<Typography variant='caption' margin='0 0 24px 0'>Tu cuenta de redes sociales está conectada a esta cuenta.</Typography>

			<RipplesButtons margin='0 0 12px 0' onClick={()=>handleAuthGoogle()} style={{borderRadius: '22px'}}>
				<LoginButton>
					<img loading="lazy" draggable="false" width='20px' height='20px' alt='Logo Google' src={GoogleIcon} />
					<Typography margin='0 0 0 23px'>
					Continuar con Google
					</Typography>
				</LoginButton>
			</RipplesButtons>

			<Button onClick={handlePreviousStep} fullWidth variant='underline'>Regresar</Button>
		</Grid>
	);
};