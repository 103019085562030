import styled, { keyframes } from 'styled-components';

export interface LoginButtonProps {
  padding?: string;
}

export const LoginButton = styled.button<LoginButtonProps>`
    background-color: ${props => props.theme.colors.white};
    padding: ${props => props.padding};
    border: 2px solid ${props => props.theme.colors.black};
    border-radius: 22px;
    height: 56px;
    display: flex;
    width: 100%;
    align-items: center;
    padding: 0 39px 0 39px;
    cursor: pointer;
`;

export interface RegistroContainerPops {
  padding?: string;
  width?: string;
}

export interface RegistroCardPops {
  maxWidth?: string;
}

export const RegistroContainer = styled.div<RegistroContainerPops>`
    border-radius: 8px;
    background-repeat: repeat;
    display: flex;
    height: calc(100vh - 72px);
    justify-content: center;
    @media (min-width: 650px) {
      align-items: center;
    }
    @media (max-width: 1060px) {
        padding: 0 5% 0 5%;
    }
    @media (max-width: 952px) {
        padding: 0 16px 0 16px;
    }
    box-sizing: border-box;
`;

export const RegistroCard = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 375px;
    border-radius: 8px;
    overflow: hidden;
    box-sizing: border-box;
    flex: 1;
    box-sizing: border-box;
`;
export const MainContainer = styled.div<RegistroContainerPops>`
    border-radius: 8px;
    background-color: ${props => props.theme.colors.white};
    box-sizing: border-box;
    width: ${props => props.width};
    padding: ${props => props.padding};
    display: flex;
    flex-direction: column;
`;
// padding: 15px 25px 25px 25px;


const show = keyframes`
0%{
  transform:  scale(1)  ;
}
15% {
  transform:  scale(.75)  ;
}
30% {
  transform:  scale(1) ;
}
45% {
  transform:  scale(.85)  ;
}
60% {
  transform:  scale(1)  ;
}
75% {
  transform:  scale(.95)  ;
}
100% {
  transform:  scale(1)  ;
}
`;

const showOpacity = keyframes`
from{
  opacity:0;
}
to{
  opacity:1;
}
`;

export const OtpContainer = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    padding: 15px 25px 25px 25px;
    background-color: ${props => props.theme.colors.white};
    background-repeat: repeat;
    animation: ${show} 0.6s, ${showOpacity} 0.6s;

`;
export interface FormGridContainerProps {
  padding?: string;
}
export const FormGridContainer = styled.div<FormGridContainerProps>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: ${props => props.padding};
  box-sizing: border-box;
  grid-column-gap: 15px;
  @media (max-width: 650px) {
    grid-template-columns: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 15px;
  }
`;