import React, { useContext, useState } from 'react';

import 'react-phone-number-input/style.css';
import { Button, Grid, TextFieldPhoneNumber, Title, Typography } from '~/components';
import { MainContainer } from '../../styled';
import { GeneralLayoutContext } from '~/context/generalLayoutContext';
import { CheckProps } from '../AuthForm';
import { AlertToastContext } from '~/context/alertToastContext';
import errors from '~/constants/errors';

export type CheckTypes = {
    error?: boolean,
    check: boolean,
    helperText?: string,
}

interface Props {
	numeroCelular: string,
	handleNumeroCelular: (e: React.ChangeEvent < HTMLInputElement >) => void,
	handleModificarNumero: VoidFunction
	modificandoTelefono: boolean
	numeroCelularCheck: CheckTypes,
	handlePreviousStep: VoidFunction | (() => Promise<void>)
	validandoCelular: boolean
	cCorreo?: string
	errorNumeroCelular: CheckProps | null
	usuarioActual: boolean
}

const CelularForm : React.FC<Props>  = ({
	handleNumeroCelular,
	numeroCelular,
	numeroCelularCheck,
	handlePreviousStep,
	errorNumeroCelular,
	handleModificarNumero,
	modificandoTelefono,
	validandoCelular,
	cCorreo,
	usuarioActual,
}) => {
	const {
		xs
	} = useContext(GeneralLayoutContext);
	const { showError } = useContext(AlertToastContext);

	const [regresando, setRegresando] = useState(false);

	const handleRegresar = () => {
		setRegresando(true);

		const result = handlePreviousStep();

		if (result instanceof Promise) {
			result
				.catch(() => {
					showError(errors.errorForm);
				})
				.finally(() => {
					setRegresando(false);
				});
		} else {
			setRegresando(false);
		}
	};

	return (
		<>
			<MainContainer padding={xs ? undefined : '5% 0 0 0'}>
				<Title variant={'h5'}>{usuarioActual ? 'Ingresa tu número de teléfono móvil' : 'Crear cuenta'}</Title>

				{ 
					usuarioActual ? (
						<Typography variant='caption' margin='8px 0 24px 0'>
							Así podremos ponernos en contacto contigo al enviar tus pedidos.
						</Typography>
					) : (
						<Typography variant='caption' margin='8px 0 24px 0'>
							Estas por crear una cuenta con el correo: <br /> <b>{cCorreo}</b> <br /><br />Por favor, ingresa tu número de teléfono móvil. <br /> Lo usaremos para enviarte actualizaciones importantes sobre tus pedidos y mantenernos en contacto.
						</Typography>
					)
				}

				<TextFieldPhoneNumber 
					fullWidth
					{...numeroCelularCheck} 
					{...errorNumeroCelular}
					value={numeroCelular} 
					countries={['MX']}
					onChange={handleNumeroCelular} 
				/>

				<Grid container spacing='8px' direction='row' flexWrap='nowrap' margin='64px 0 0 0'>
					<Button onClick={handleRegresar} loading={regresando} loadingColor='primary' fullWidth variant='outlined-round'>Regresar</Button>
					<Button onClick={handleModificarNumero} disabled={!numeroCelularCheck.check || validandoCelular || !errorNumeroCelular?.check} loading={validandoCelular || modificandoTelefono} fullWidth variant='contained-round'>Siguiente</Button>
				</Grid>
			</MainContainer>
		</>
	);
};

export default (CelularForm);