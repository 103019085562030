import React from 'react';
import Logo from '../../assets/images/carapana.webp';
import {Divider, PanaDividerContainer} from './styled';
type Props = {
  margin?: string,
};
const PanaDivider : React.FC<Props> = ({ margin } : Props) => {
	return (
		<PanaDividerContainer margin={margin}>
			<Divider />
			<div className='imageContainer'>
				<div className='circularContainer'>
					<img loading="lazy" draggable="false" width="22px" alt='Cara Pana' src={Logo}></img>
				</div>
			</div>
		</PanaDividerContainer>
	);
};
export default (PanaDivider);