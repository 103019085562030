//errors.ts

interface IDictionary<TValue> {
    [id: string]: TValue;
}

const errors: IDictionary<string> = {
	emailError: 'Email inválido.',
	cpError: 'Código postal inválido.',
	nombresError: 'Inserta un nombre válido.',
	apellidosError: 'Inserta un apellido válido.',
	passwordError: 'Contraseña incorrecta, debe tener mínimo 8 caracteres, una letra mayúscula, una letra minúscula y un número.',
	numeroCelular: 'Número de celular inválido.',
	numeroCelularExtranjero: 'Número de celular inválido, debe ser un número mexicano.',
	checkPassword: 'Verfica tu contraseña',
	errorChangePassword: 'Ha ocurrido un error al cambiar tu contraseña, intente más tarde.',
	errorForm: 'Ha ocurrido un error, intente más tarde.',
	calleErrorForm: 'Calle inválida',
	caracErrorForm: 'Caracter inválido',
	numeroExteriorErrorForm: 'Número exterior inválido',
	numeroInteriorErrorForm: 'Número interior inválido',
	dinamicaFolioError: 'Inserta un folio válido',
	redirectDinamicasMessage: 'Serás redirigido a la página de dinamicas para completar el proceso.'
};

export default errors;