import { useContext, useState } from 'react';
import { AlertToastContext } from '~/context/alertToastContext';
import { CookiesContext } from '~/context/cookiesContext';
import { ERRORES_FRECUENTES } from '../../../api/src/utils/errors';

export const useTerminosCondiciones = (onSuccess?: () => void) => {
	const apiUrl = import.meta.env.VITE_API_URL;
	const { showSuccess, showError } = useContext(AlertToastContext);
	const { headersApi, handleUserInfo, macAddress, token } = useContext(CookiesContext);


	const [terminosCheck, setTerminosCheck] = useState(false);
	const handleTerminosCheck = () => {
		setTerminosCheck(!terminosCheck);
	};

	const [aceptandoTerminos, setAceptandoTerminos] = useState(false);
	const handleAceptarTerminos = async () => {
		setAceptandoTerminos(true);

		if (macAddress && token) {
			fetch(`${apiUrl}/APPWEB/AceptarTerminosCondiciones`, {
				headers: headersApi,
				method: 'POST'
			})
				.then((res) => res.json())
				.then(async (response) => {
					if (response?.success) {
						showSuccess('Terminos aceptados exitosamente');

						handleUserInfo(token, macAddress, { nEstatus: response?.Result?.nEstatus });
	
						onSuccess?.();
					} else {
						showError(ERRORES_FRECUENTES.ef0);
					}
				})
				.finally(() => {
					setAceptandoTerminos(false);
				});
		}
	};

	return {
		aceptandoTerminos,
		handleAceptarTerminos,
		terminosCheck,
		handleTerminosCheck,
	};
};