import { useCallback, useEffect, useRef, useState } from 'react';
import Grid from '../Grid';
import TextField from '../TextField';
import { TextFieldLabel } from '../TextField/styled';
import { FechasDisponiblesTypes } from '~/interfaces';
import Typography from '../Typography';

interface Props {
	value: string | null;
	handleValue: (e: string) => void;
	helperText?: string;
	error?: boolean;
	check?: boolean
	label?: string;
	padding?: string;
	nRangoLibreFechasDisponibles?: boolean;
	dFechasAPartirDe?: string;
	fullWidth?: boolean;
	dFechasDisponibles?: FechasDisponiblesTypes[];
	validateDate?: ((val: string) => boolean) | ((dateValue: string, nRangoLibreFechasDisponibles?: boolean, dFechasAPartirDe?: string, dFechasDisponibles?: FechasDisponiblesTypes[]) => boolean);
}

const focusOnInput = (id: string) => {
	document.getElementById(id)?.focus();
};

const DateInput = ({ label, value, handleValue, dFechasAPartirDe, check, dFechasDisponibles, error, helperText, nRangoLibreFechasDisponibles, padding, validateDate }: Props) => {
	const idsPrefix = useRef(`${parseInt((Math.random() * Date.now()).toString())}`).current;
	const diaId = `${idsPrefix}-dia`;
	const mesId = `${idsPrefix}-mes`;
	const anioId = `${idsPrefix}-año`;

	// Estado para los campos de fecha
	const [date, setDate] = useState({
		day: '',
		month: '',
		year: '',
	});
	const [validDate, setValidDate] = useState(true);
	const [errors, setErrors] = useState({
		day: '',
		month: '',
		year: '',
	});

	useEffect(() => {
		const newDate = `${date.year.padStart(4,'0')}-${date.month.padStart(2,'0')}-${date.day.padStart(2,'0')}`;

		if (!errors.day && !errors.month && !errors.year && date.day && date.month && date.year) {
			handleValue(newDate);

			setValidDate( (newDate && validateDate) ? validateDate(newDate, nRangoLibreFechasDisponibles, dFechasAPartirDe, dFechasDisponibles) : true );
		} else {
			handleValue('');
		}
	}, [date]);

	useEffect(() => {
		if (!value) {
			return;
		}

		const [year,month,day] = value.split('-');

		setDate({
			day,
			month,
			year,
		});
	}, []);

	// Validar día según el mes
	const validateDay = useCallback((day: string, month: string, year:string) => {
		if (!day) return 'El día es requerido';
		const dayNum = parseInt(day);
		if (isNaN(dayNum) || dayNum < 1) return 'Día inválido';

		const daysInMonth = new Date(parseInt(year), parseInt(month), 0).getDate();
		if (dayNum > daysInMonth) return `El mes seleccionado solo tiene ${daysInMonth} días`;

		return '';
	}, []);

	// Validar mes
	const validateMonth = useCallback((month: string) => {
		if (!month) return 'El mes es requerido';
		const monthNum = parseInt(month);
		if (isNaN(monthNum) || monthNum < 1 || monthNum > 12) return 'Mes inválido';
		return '';
	}, []);

	// Validar año
	const validateYear = useCallback((year: string) => {
		if (!year) return 'El año es requerido';
		const yearNum = parseInt(year);
		if (isNaN(yearNum) || yearNum < 1900 || yearNum > 2100) return 'Año inválido';
		return '';
	}, []);

	const handleChange = useCallback(
		(field: string, value: string, event: React.KeyboardEvent<HTMLInputElement> | null) => {
			if (event && (event.key === '+' || event.key === '-' || event.key === 'e' || (!/^\d$/.test(event.key) && event.key !== 'Backspace' && event.key !== 'Tab' && event.key !== 'ArrowLeft' && event.key !== 'ArrowRight'))) {
				event.preventDefault();
				return;
			}
	
			// Eliminar caracteres no numéricos
			const numericValue = value.replace(/[^0-9]/g, '');
	
			// Aplicar límites según el campo
			const maxLength = field === 'year' ? 4 : 2;
			const trimmedValue = numericValue.slice(0, maxLength);
	
			setDate((prev) => {
				const newDate = { ...prev, [field]: trimmedValue };
				const newErrors = { ...errors };
	
				// Validaciones
				if (field === 'month') {
					newErrors.month = validateMonth(trimmedValue);
					if (newDate.day) {
						newErrors.day = validateDay(newDate.day, trimmedValue, newDate.year);
					}
				} else if (field === 'day') {
					newErrors.day = validateDay(trimmedValue, newDate.month, newDate.year);
				} else if (field === 'year') {
					newErrors.year = validateYear(trimmedValue);
					if (newDate.day) {
						newErrors.day = validateDay(newDate.day, newDate.month, trimmedValue);
					}
				}

				setErrors(newErrors);

				// Solo mover el foco si el valor está aumentando (no cuando se está editando)
				if (trimmedValue.length === maxLength && trimmedValue.length > prev[field as keyof typeof prev].length) {
					if (field === 'day') {
						focusOnInput(mesId);
					} else if (field === 'month') {
						focusOnInput(anioId);
					}
				}

				// Retroceder input al borrar
				if (trimmedValue.length === 0 && event?.key === 'Backspace') {
					if (field === 'month') {
						focusOnInput(diaId);
					} else if (field === 'year') {
						focusOnInput(mesId);
					}
				}

				return newDate;
			});
		},
		[validateDay, validateMonth, validateYear, errors, diaId, mesId, anioId]
	);

	return (
		<Grid container direction="column" padding={padding}>
			<Grid item padding="0">
				<TextFieldLabel>{label}</TextFieldLabel>
			</Grid>

			<Grid container flexWrap="nowrap" spacing="8px">
				<Grid item flex>
					<TextField
						inputId={diaId}
						error={error || !!errors.day || !validDate}
						check={check}
						type="number"
						maxlength={2}
						minlength={0}
						noIcon
						label="Día"
						value={date.day}
						onKeyDown={(e) => handleChange('day', date.day, e)}
						onChange={(e) => handleChange('day', e.target.value, null)}
						placeholder="DD"
					/>
				</Grid>

				<Grid item flex>
					<TextField
						inputId={mesId}
						error={error || !!errors.month || !validDate}
						check={check}
						type="number"
						maxlength={2}
						minlength={0}
						noIcon
						label="Mes"
						value={date.month}
						onKeyDown={(e) => handleChange('month', date.month, e)}
						onChange={(e) => handleChange('month', e.target.value, null)}
						placeholder="MM"
					/>
				</Grid>

				<Grid item flex>
					<TextField
						inputId={anioId}
						error={error || !!errors.year || !validDate}
						check={check}
						type="number"
						maxlength={4}
						minlength={0}
						noIcon
						label="Año"
						value={date.year}
						onKeyDown={(e) => handleChange('year', date.year, e)}
						onChange={(e) => handleChange('year', e.target.value, null)}
						placeholder="AAAA"
					/>
				</Grid>
			</Grid>

			<Grid item padding="0">
				<Typography padding="5px 0 0 0" variant="regular" textAlign="start" color='red'>
					{helperText || errors.day || errors.month || errors.year}
				</Typography>
			</Grid>
		</Grid>
	);
};

export default DateInput;
