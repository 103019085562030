import React, { useContext } from 'react';

import { Button, Grid, TokenAuth, Typography } from '~/components';
import { maskPhoneNumber } from '~/utils';
import { MainContainer } from '../../styled';
import { GeneralLayoutContext } from '~/context/generalLayoutContext';

interface Props {
	handlePreviousStep: VoidFunction
	numeroCelular: string,
	tiempoRestante?: number
	handleReenviarOTP: () => void
	handleValidarOTP: (cOTP: string) => void
	reenviandoOTP: boolean
	validandoOTP: boolean
}

const OTPCelular : React.FC<Props> = ({
	handlePreviousStep,
	numeroCelular,
	handleReenviarOTP,
	handleValidarOTP,
	reenviandoOTP,
	tiempoRestante = 0,
	validandoOTP
}) => {
	const {
		xs
	} = useContext(GeneralLayoutContext);

	return (
		<MainContainer padding={!xs ? '5% 0 0 0' : undefined}>
			<Typography variant={'subtitle3regular'} margin='0 0 24px 0'>Ingrese el código de 4 dígitos que enviamos al <strong>{maskPhoneNumber(numeroCelular)}</strong></Typography>

			<TokenAuth 
				onComplete={(value: string) => handleValidarOTP(value)}
				length={4}
				secret={true}
			/>

			<Typography variant='captionBold' margin='24px 0 0 0'>¿No recibiste el código?</Typography>


			<Grid container spacing='16px' direction='column' flexWrap='nowrap' margin='64px 0 0 0'>
				<Button loading={reenviandoOTP || validandoOTP} onClick={handleReenviarOTP} disabled={reenviandoOTP || tiempoRestante > 0 || validandoOTP} fullWidth variant='contained-round'>
					{`Reenviar ${tiempoRestante === 0 ? '' : `en (${tiempoRestante}s)`}`}
				</Button>

				<Button onClick={handlePreviousStep} fullWidth variant='outlined-round'>Cambiar Número</Button>
			</Grid>
		</MainContainer>
	);
};

export default (OTPCelular);