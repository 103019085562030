// responsive
import React, { useContext, useEffect } from 'react';
import { CustomCarrousel } from '~/components';
import { GeneralLayoutContext } from '~/context/generalLayoutContext';
import { useAuth, useValidUserRedirect } from '~/hooks';
import useWizard from '~/hooks/useWizard';
import Registro from './containers/registro';
import AuthForm from './containers/AuthForm';
import { CookiesContext } from '~/context/cookiesContext';
import { MainContainer, RegistroCard, RegistroContainer } from './styled';
import CelularForm from './containers/CelularForm';
import AvisoConfirmaNumero from './containers/AvisoConfirmaNumero';
import OTPCelular from './containers/otpCelular';
import { TodoListo } from './containers/TodoListo';
import TerminosCondiciones from './containers/terminosCondiciones';
import { CuentaGoogleDetectada } from './containers/CuentaGoogleDetectada';

export enum ESTATUS_USUARIO {
	Inactivo = 1,
	Activo = 2,
	Deshabilitado = 3,
	CorreoInvalido = 4,
	FinalizarRegistro = 5,
	CuentaEliminada = 6,
	CelularSinValidar = 7,
	OTP_Cooldown = 8,
	ValidarOTP = 10,
	NecesitaAceptarTerminos = 9,
}

const Auth = () => {
	const {
		handleShowBack,
		xs,
	} = useContext(GeneralLayoutContext);

	const {
		userInfo,
		handleDoneInitialLoading,
		handleLogout,
		handleUserInfo,
		token,
		macAddress
	} = useContext(CookiesContext);

	const {
		handleActivePosition,
		handleNext,
		handleBack,
		activePosition,
	} = useWizard(0);

	const {
		showRecuperarContrenia,
		email,
		emailRegistroCheck,
		handleEmail,

		password,
		handlePassword,
		disabledAuthSubmit,
		loading,
		errorTextField,
		handleRedirect,
		remeberCheck,
		emailCheck,
		passwordCheck,
		handleAuthGoogle,
		handleAuthFB,
		handleAuth,
		nombres,
		handleNombres,
		apellidoPaterno,
		loadingUserData,
		handleApellidoPaterno,
		apellidoMaterno,
		handleApellidoMaterno,
		fechaDeNacimiento,
		handleFechaDeNacimiento,
		genero,
		handleGenero,
		dataGeneros,
		nombresCheck,
		apellidoPaternoCheck,
		apellidoMaternoCheck,
		fechaDeNacimientoCheck,
		generoCheck,
		handleModificarInfoUsuario,
		disabledRegistro,
		notificacionesCheck,
		handleNotificacionesCheck,

		numeroCelular,
		handleNumeroCelular,
		numeroCelularCheck,
		validandoCelular,
		errorNumeroCelular,
		modificandoTelefono,
		handleModificarNumero,
		
		userCredential,
		handleUserCredential,
		handleKeyDownNumeroTelefono,
		handleCountryCode,
		countryCode,
		shouldRenderEmailInput,
		numeroCheck,
		
		handleReenviarOTP,
		handleValidarOTP,
		reenviandoOTP,
		validandoOTP,
		progress,
		
		aceptandoTerminos,
		handleAceptarTerminos,
		handleTerminosCheck,
		terminosCheck,
	} = useAuth({
		onFinishOTP: () => {
			handleActivePosition(1);
		},
		onFinishTerminosCondiciones: () => {
			handleActivePosition(1);
		},
		onLogout: () => {
			handleActivePosition(0);
		},
		onLoginTerminosCondiciones: () => {
			handleActivePosition(1);
		},
		handleNext,
	});

	const necesitaValidarOTP = userInfo?.nEstatus === 7 || userInfo?.nEstatus === 8;
	const flujoRegistroCorreo = necesitaValidarOTP && userInfo.cCorreo;
	const tieneTelefonoValido = !isNaN(parseInt(userInfo?.cNumero_Celular || '')) && userInfo?.cNumero_Celular?.length === 10;
	const flujoUsuarioActual = necesitaValidarOTP && ((userInfo?.nombre && userInfo.nombre !== 'Usuario') || !!userInfo?.cApellido_Materno || !!userInfo?.cApellido_Paterno);
	const finalizarRegistro = userInfo?.nEstatus === 5;
	const necesitaTerminos = userInfo?.nEstatus === 9;
	const flujoGoogle = userInfo?.bGoogleDetectado;

	useEffect(() => {
		if (flujoUsuarioActual) {
			handleActivePosition(1);
		} else if (necesitaTerminos) {
			handleActivePosition(1);
		} else if (flujoGoogle) {
			handleActivePosition(1);
		} else if (!userInfo?.invitado) {
			handleActivePosition(1);
		}
	}, [userInfo?.nEstatus !== undefined]);

	useEffect(() => {
		handleShowBack('/', 'Volver a tienda');
		handleDoneInitialLoading();
	}, []);

	useValidUserRedirect(true);

	useEffect(() => {
		if (flujoUsuarioActual) {
			handleActivePosition(1);
		} else if (!userInfo?.invitado) {
			handleActivePosition(1);
		}
	}, [userInfo?.nEstatus !== undefined]);

	return (
		<>
			{/* {xs && <PanaShow />} */}

			<RegistroContainer >
				<RegistroCard >
					<CustomCarrousel 
						activePosition={activePosition} 
						items={[
							<MainContainer key={'AuthForm'} padding={xs ? undefined : '5% 0 0 0'}>
								<AuthForm
									remeberCheck={remeberCheck}
									errorTextField={errorTextField}

									userCredential={userCredential}
									handleUserCredential={handleUserCredential}
									handleKeyDownNumeroTelefono={handleKeyDownNumeroTelefono}
									handleCountryCode={handleCountryCode}
									shouldRenderEmailInput={shouldRenderEmailInput}
									numeroCelularCheck={numeroCheck}
									countryCode={countryCode}
									handlePassword={handlePassword}
									passwordCheck={passwordCheck}
									emailCheck={emailCheck}
									password={password}
									handleAuthGoogle={handleAuthGoogle}
									handleAuthFB={handleAuthFB}
									showRecuperarContrenia={showRecuperarContrenia}

									handleAuth={() => handleAuth(1)}
									disabledAuthSubmit={disabledAuthSubmit}
									loading={loading}
								/>
							</MainContainer>,

							flujoUsuarioActual && (
								<AvisoConfirmaNumero 
									handleNextStep={() => {
										tieneTelefonoValido 
											? handleActivePosition(activePosition + 2) 
											: handleNext();
									}}
								/>
							),

							flujoRegistroCorreo && (
								<CelularForm 
									numeroCelular={numeroCelular}
									handleNumeroCelular={handleNumeroCelular}
									numeroCelularCheck={numeroCelularCheck}
									errorNumeroCelular={errorNumeroCelular}
									validandoCelular={validandoCelular}
									key={'CelularForm'}
									cCorreo={userCredential || userInfo.cCorreo}
									usuarioActual={flujoUsuarioActual}
									modificandoTelefono={modificandoTelefono}
									handleModificarNumero={handleModificarNumero}
									handlePreviousStep={() => new Promise<void>((resolve, reject) => {
										try {
											if (flujoUsuarioActual) {
												handleBack();
												resolve();
											} else {
												handleLogout(() => {
													handleBack();
													resolve();
												});
											}
										} catch (error) {
											reject(error);
										}
									})}
								/>
							),

							necesitaValidarOTP && (
								<OTPCelular 
									key={'OTPCelular'}
									handlePreviousStep={handleBack}
									numeroCelular={numeroCelular || userInfo?.cNumero_Celular || ''}
									handleReenviarOTP={handleReenviarOTP}
									handleValidarOTP={handleValidarOTP}
									tiempoRestante={progress}
									reenviandoOTP={reenviandoOTP}
									validandoOTP={validandoOTP}
								/>
							),

							flujoGoogle && (
								<CuentaGoogleDetectada 
									handleAuthGoogle={handleAuthGoogle}
									handlePreviousStep={async () => {
										token && macAddress && handleUserInfo(token, macAddress, { bGoogleDetectado: false });
										handleBack();
									}}
								/>
							),

							(finalizarRegistro || (necesitaTerminos && !userInfo.bLoginTerminosCondiciones)) && (
								<Registro
									key={'RegistroForm'}
									xs={xs}
									nombres={nombres}
									handleNombres={handleNombres}
									apellidoPaterno={apellidoPaterno}
									handleModificarInfoUsuario={handleModificarInfoUsuario}
									loading={loadingUserData}
									handleApellidoPaterno={handleApellidoPaterno}
									apellidoMaterno={apellidoMaterno}
									handleApellidoMaterno={handleApellidoMaterno}
									fechaDeNacimiento={fechaDeNacimiento}
									handleFechaDeNacimiento={handleFechaDeNacimiento}
									genero={genero}
									handleGenero={handleGenero}
									dataGeneros={dataGeneros}
									nombresCheck={nombresCheck}
									apellidoPaternoCheck={apellidoPaternoCheck}
									apellidoMaternoCheck={apellidoMaternoCheck}
									fechaDeNacimientoCheck={fechaDeNacimientoCheck}
									generoCheck={generoCheck}
									disabledRegistro={disabledRegistro}
									email={email}
									handleEmail={handleEmail}
									emailCheck={emailRegistroCheck}
									handleRegistro={()=>handleModificarInfoUsuario()} 
									notificacionesCheck={notificacionesCheck}
									handleNotificacionesCheck={handleNotificacionesCheck}
								/>
							),

							necesitaTerminos && (
								<TerminosCondiciones 
									handlePreviousStep={() => new Promise<void>((resolve, reject) => {
										try {
											if (userInfo.bLoginTerminosCondiciones) {
												handleLogout(() => {
													if (token && macAddress) {
														handleUserInfo(token, macAddress, { bLoginTerminosCondiciones: false });
													}

													handleBack();
													resolve();
												});
											} else {
												handleBack();
												resolve();
											}
										} catch (error) {
											reject(error);
										}
									})}
									handleAceptarTerminos={handleAceptarTerminos}
									handleTerminosCheck={handleTerminosCheck}
									aceptandoTerminos={aceptandoTerminos}
									terminosCheck={terminosCheck}
								/>
							),

							<TodoListo 
								key={'TodoListo'}
								handleRedirect={handleRedirect}
							/>,
						].filter(value => !!value)}
					/>
				</RegistroCard>
			</RegistroContainer>
		</>
	);
};

export default (Auth);