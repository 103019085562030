import React, { useContext, useEffect } from 'react';
import { GeneralLayoutContext } from '~/context/generalLayoutContext';
import { MainContainer } from '../../styled';
import { Button, CardMedia, Grid, Title, Typography } from '~/components';
import PanaConfirmando from '~/assets/images/panaConfirmando.webp';

interface props {
	handleRedirect: VoidFunction
}

export const TodoListo: React.FC<props> = ({
	handleRedirect
}) => {
	const { xs } = useContext(GeneralLayoutContext);

	useEffect(() => {
		const timeout = setTimeout(async () => {
			handleRedirect();
		}, 3000);

		return () => {
			clearTimeout(timeout);
		};
	}, []);

	return (
		<MainContainer padding={xs ? '0' : '5% 0 0 0'}>
			<Grid container direction='row' flexWrap='nowrap'>
				<Grid container direction='column'>
					<Title variant="h5" margin="0 0 8px 0">
						¡Todo listo!
					</Title>

					<Typography variant="caption">Iniciarás sesión en tu cuenta en un momento. Si no sucede nada, haz clic en Continuar.</Typography>
				</Grid>

				<CardMedia maxwidth='100px' src={PanaConfirmando} staticImg />
			</Grid>

			<Button margin="64px 0 0 0" onClick={handleRedirect} fullWidth variant="contained-round">
				Continuar
			</Button>
		</MainContainer>
	);
};
