import { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { CookiesContext } from '~/context/cookiesContext';

const useValidUserRedirect = (mustStaredAsInvitado?: boolean, invitadoNotAllowed?: boolean) => {
	const {
		userInfo,
	} = useContext(CookiesContext);

	const navigate = useNavigate();
	
	const [staredAsInvitado] = useState(userInfo?.invitado);

	useEffect(() => {
		
		if(invitadoNotAllowed ? (userInfo?.invitado) : (!userInfo?.invitado && userInfo?.nEstatus === 2 && (mustStaredAsInvitado ? (!staredAsInvitado) : true))){
			navigate('/');

		}
	}, [userInfo]);

    
};

export default useValidUserRedirect;