import { useContext, useEffect, useState } from 'react';
import { AlertToastContext } from '~/context/alertToastContext';
import { CookiesContext } from '~/context/cookiesContext';
import { Colonias, DireccionesTypes, DireccionType, SelectItemsTypes } from '~/interfaces';
import { errorDetallesRegex, handleHelperValidRegex, handleValidCodigoPostal, handleValidHouseNumber, handleValidText } from '~/utils/validations';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import errors from '~/constants/errors';

const useDirecciones = (isCheckout?: boolean) => {
	const apiUrl = import.meta.env.VITE_API_URL;
	const navigate = useNavigate();
	const handleGoToMain = () => {
		navigate('/');
	};
	const {showError} = useContext(AlertToastContext);
	const {
		headersApi,
		token,
		isUserActive
	} = useContext(CookiesContext);

	const [dataDirecciones, setDataDirecciones] = useState<DireccionesTypes[] | undefined>(undefined);
    
	const handleDataCarrito = () => {
		if(!isCheckout || !isUserActive){
			setDataDirecciones(undefined);
		}

		if (!isUserActive) {
			return;
		}

		fetch(`${apiUrl}/APPWEB/ObtenerDirecciones`, {
			method: 'POST',
			headers: headersApi,
		}).then((res) => res.json()).then((response) => {
			if (response?.Result) {
				const helper = response.Result.dataDirecciones;

				setDataDirecciones(helper);
			}
		}).catch((error) => {
			console.error(error, 'error');
		});
	};

	useEffect(() => {
		handleDataCarrito();
	}, [token, isUserActive]);

	// Direcciones
	const [direccionPredeterminada, setDireccionPredeterminada] = useState<boolean>(false);

	const handleDireccionPredeterminada = () => {
		setDireccionPredeterminada(!direccionPredeterminada);
	};

	const [disabledDireccionPredeterminada, setDisabledDireccionPredeterminada] = useState(false);
	
	// codigoPostal
	const [codigoPostal, setCodigoPostal] = useState('');
	const handleCodigoPostal = (e: React.ChangeEvent < HTMLInputElement > ) => {
		setCodigoPostal(e.target.value);
		setCodigoPostalError(false);
	};

	// colonia
	const [dataColonias, setDataColonias] = useState<Colonias | undefined>(undefined);

	const [codigoPostalError, setCodigoPostalError] = useState(false);

	const [loadingColonias, setLoadingColonias] = useState(false);

	const resetDireccion = () => {
		setDataColonias(undefined);
		setColonia(coloniaInitialValue);
		setCalle('');
		setNumeroExterior('');
		setNumeroInterior('');
		setEntreCalle('');
		setIndicacionesAdicionales('');
		setTipoDomicilio(tipoDomicilioInitialValue);
	};
    
	const handleDataColonias = () => {
		if (codigoPostal.length >= 5) {
			setLoadingColonias(true);
			const body = {
				cCodigoPostal: codigoPostal,
			};
			fetch(`${apiUrl}/APPWEB/ObtenerColonias`, {
				method: 'POST',
				headers: headersApi,
				body: JSON.stringify(body),
			}).then((res) => res.json()).then((response) => {
			
				if (response?.Result?.dataColonias) {
					const helper = response.Result.dataColonias
                ;
					setDataColonias(helper);
				} else {
					setDataColonias(undefined);
					setCodigoPostalError(true);
					resetDireccion();
				}
				setLoadingColonias(false);

			}).catch((error) => {
				setLoadingColonias(false);
				console.error(error, 'error');
			});
		} else {
			resetDireccion();
		}
	};

	useEffect(() => {
		handleDataColonias();
	}, [codigoPostal.length >= 5]);

	const coloniaInitialValue = {
		nValor: 0,
		cValor: 'Selecciona una colonia',
	};

	const [colonia, setColonia] = useState<SelectItemsTypes>(coloniaInitialValue);
	const handleColonia = (e: SelectItemsTypes ) => {
		setColonia(e);
	};

	// calle
	const [calle, setCalle] = useState('');
	const handleCalle = (e: React.ChangeEvent < HTMLInputElement > ) => {
		setCalle(e.target.value);
	};

	// numeroExterior
	const [numeroExterior, setNumeroExterior] = useState('');
	const handleNumeroExterior = (e: React.ChangeEvent < HTMLInputElement > ) => {
		setNumeroExterior(e.target.value);
	};

	// numeroInterior
	const [numeroInterior, setNumeroInterior] = useState('');
	const handleNumeroInterior = (e: React.ChangeEvent < HTMLInputElement > ) => {
		setNumeroInterior(e.target.value);
	};

	// entreCalle
	const [entreCalle, setEntreCalle] = useState('');
	const handleEntreCalle = (e: React.ChangeEvent < HTMLInputElement > ) => {
		setEntreCalle(e.target.value);
	};

	const tipoDomicilioInitialValue = {
		nValor: 0,
		cValor: 'Selecciona un tipo de domicilio',
	};
    
	// tipoDomicilio
	const [dataTipoDomicilios, setDataTipoDomicilios] = useState<SelectItemsTypes[] | undefined>(undefined);
	const [tipoDomicilio, setTipoDomicilio] = useState<SelectItemsTypes>(tipoDomicilioInitialValue);

	useEffect(() => {
		if (dataTipoDomicilios === undefined) {
			fetch(`${apiUrl}/APPWEB/ObtenerCatalogosTiposDatos?cLlave=APPWEBCAT_Tipo_Domicilio`, {
				method: 'GET',
			}).then((res) => res.json()).then((response) => {
				if (response?.Result?.[0]) {
					const helper = response.Result[0];
					setDataTipoDomicilios(helper);
					setTipoDomicilio(helper[0]);
				}
			}).catch((error) => {
				console.error(error, 'error');
			});
		}
	}, []);

	const handleTipoDomicilio = (e: SelectItemsTypes) => {
		setTipoDomicilio(e);
	};

	// indicadoresAdicionales
	const [indicadoresAdicionales, setIndicacionesAdicionales] = useState('');
	const handleIndicacionesAdicionales = (e: React.ChangeEvent < HTMLInputElement > ) => {
		setIndicacionesAdicionales(e.target.value);
	};


	const errorCodigoPostal = handleValidCodigoPostal(codigoPostal, codigoPostalError);
	const erroCalle = handleValidText(calle, 'calleErrorForm');
	const errorNumeroExt = handleValidHouseNumber(numeroExterior, 'numeroExteriorErrorForm');
	const errorNumeroInt = handleValidHouseNumber(numeroInterior, 'numeroInteriorErrorForm');
	const errorEntreCalle = handleValidText(entreCalle, 'caracErrorForm');
	const errorIndic = handleHelperValidRegex(indicadoresAdicionales, errorDetallesRegex, errors['caracErrorForm']);

	const errorGuardarDireccion = errorCodigoPostal.error || erroCalle.error || errorNumeroExt.error || errorNumeroInt.error || errorEntreCalle.error || errorIndic.error || codigoPostal === '' || colonia.nValor === 0 || calle === '' || numeroExterior === '' || tipoDomicilio.nValor === 0 || dataColonias === undefined || indicadoresAdicionales === '';

	const [loading, setLoading] = useState(false);

	const handleGuardarDireccion = (handleBack?: (value?: DireccionType) => void) => {
		setLoading(true);

		if(!errorGuardarDireccion){
			const body = {
				nColonia: colonia.nValor,
				cCalle: calle,
				cNumeroExterior: numeroExterior,
				cNumeroInterior: numeroInterior,
				cEntreCalle: entreCalle,
				nTipoDomicilio: tipoDomicilio.nValor,
				cReferencias: indicadoresAdicionales,
				bDireccionPredeterminada: direccionPredeterminada
			};
		
			fetch(`${apiUrl}/APPWEB/RegistroDirecciones`, {
				body: JSON.stringify(body),
				method: 'POST',
				headers: headersApi
			}).then((res) => res.json()).then((response) => {

				if(response.Result?.done && !response.Result?.error){
					handleDataCarrito();
					if(handleBack){
						handleBack(
							{
								nDireccion: response.Result?.data?.nDireccion,
								cCodigoPostal: codigoPostal,
								nColonia: colonia.nValor,
								cColonia: colonia.cLlave,
								cCalle: calle,
								cNumeroExterior: numeroExterior,
								cNumeroInterior: numeroInterior,
								cEntreCalle: entreCalle,
								nTipoDomicilio: tipoDomicilio.nValor,
								cTipoDomicilio: (_.find(dataTipoDomicilios, o=>o.nValor === tipoDomicilio.nValor)?.cValor) || '',
								cIndicadoresAdicionales: indicadoresAdicionales,
								cMunicipio: dataColonias.cMunicipio,
								cEstado: dataColonias.cEstado
							}
						);
					}

					setLoading(false);

				} else {
					showError(response?.Result?.error);
					setLoading(false);

				}
			}).catch((error) => {
				console.error(error, 'error');
				setLoading(false);
				showError(errors.errorForm);
			});
		}

	};
	// wizard
	
	const [activePosition, setActivePosition] = useState(0);
	const handleNextWizard = () => {
		setActivePosition(activePosition + 1);
	};
	const handleBackWizard = () => {
		setActivePosition(activePosition - 1);
	
	};
	// direccionHooks
	
	const [openDomicilio, setOpenDomicilio] = useState(false);

	const handleCloseDomicilio = () => {
		setOpenDomicilio(false);
		setNDireccion(null);
	};
	const handleBackDireccionesHelper = () => {
		if(activePosition === 0) {
			setOpenDomicilio(false);
		} else {
			handleBackWizard();
		}
	};

	const [domicilioSelected, setDomicilioSelected] = useState<DireccionesTypes | null>(null);

	const handleDomicilioId = (value: DireccionesTypes) => {
		setDomicilioSelected(value);
	};

	const handleDomicilio = () => {
		if(domicilioSelected !== null && activePosition === 0) {
			/* const copy = {...checkoutOrderInfo};
			copy.direccion = domicilioSelected;
			setCheckoutOrderInfo(copy); */

			// selecciona domicilio predeterminado
			handleCloseDomicilio();
		}
	};

	// Delete Direccion
	const [nDireccion, setNDireccion] = useState<null | number>(null);

	const [openDeleteDireccion, setOpenDeleteDireccion] = useState(false);

	const hadleOpenDeleteDireccion = (nDireccion: number) => {
		setOpenDeleteDireccion(true);
		setNDireccion(nDireccion);
	};
	
	const hadleCloseDeleteDireccion = () => {
		setOpenDeleteDireccion(false);
		setNDireccion(null);
		
	};

	const handleDeleteDireccion = () => {
		setLoading(true);

		if(nDireccion){
			const body = {
				nDireccion: nDireccion,
			};
		
			fetch(`${apiUrl}/APPWEB/ModificaEstatusDirecciones`, {
				body: JSON.stringify(body),
				method: 'POST',
				headers: headersApi
			}).then((res) => res.json()).then((response) => {

				if(response.Result?.done && !response.Result?.error){

					setLoading(false);
					setDataDirecciones(response.Result.dataDirecciones);
					hadleCloseDeleteDireccion();
				} else {
					showError(response?.Result?.error);
					setLoading(false);

				}
			}).catch((error) => {
				console.error(error, 'error');
				setLoading(false);
				showError(errors.errorForm);
			});
		}
	};

	const handleOpenEditarDireccion = (item: DireccionesTypes) => {
		setNDireccion(item.nDireccion || null);
		handleOpenDomicilio();

		setCodigoPostal(item.cCodigo_Postal || '');
		setColonia({
			cValor: item.cColonia || coloniaInitialValue.cValor, 
			nValor: item.nColonia || coloniaInitialValue.nValor
		});
		setCalle(item.cCalle || '');
		setEntreCalle(item.cEntreCalle || '');
		setNumeroExterior(item.cNumeroExterior || '');
		setNumeroInterior(item.cNumeroInterior || '');
		setEntreCalle(item.cEntreCalle || '');
		setTipoDomicilio({
			cValor: item.cTipoDomicilio || dataTipoDomicilios?.[0].cValor || tipoDomicilioInitialValue.cValor, 
			nValor: item.nTipoDomicilio || dataTipoDomicilios?.[0].nValor || tipoDomicilioInitialValue.nValor
		});
		setIndicacionesAdicionales(item.cReferencias || '');
		setDireccionPredeterminada(item.bDireccionPredeterminada || false);
		setDisabledDireccionPredeterminada(item.bDireccionPredeterminada || false);
	};

	const handleEditarDireccion = (handleBack?: (value?: DireccionType) => void) => {
		setLoading(true);

		if(nDireccion){
			const body = {
				nColonia: colonia.nValor,
				cCalle: calle,
				cNumeroExterior: numeroExterior,
				cNumeroInterior: numeroInterior,
				cEntreCalle: entreCalle,
				nTipoDomicilio: tipoDomicilio.nValor,
				cReferencias: indicadoresAdicionales,
				nDireccion: nDireccion,
				bDireccionPredeterminada: direccionPredeterminada
			};
		
			fetch(`${apiUrl}/APPWEB/ModificaDireccion`, {
				body: JSON.stringify(body),
				method: 'POST',
				headers: headersApi
			}).then((res) => res.json()).then((response) => {
				if(response.Result?.done && !response.Result?.error){
					setLoading(false);
					setDataDirecciones(response.Result.dataDirecciones);
					handleCloseDomicilio();
					if(handleBack){
						handleBack(
							{
								nDireccion: nDireccion,
								cCodigoPostal: codigoPostal,
								nColonia: colonia.nValor,
								cColonia: colonia.cLlave,
								cCalle: calle,
								cNumeroExterior: numeroExterior,
								cNumeroInterior: numeroInterior,
								cEntreCalle: entreCalle,
								nTipoDomicilio: tipoDomicilio?.nValor || 1,
								cTipoDomicilio: (_.find(dataTipoDomicilios, o=>o.nValor === tipoDomicilio?.nValor)?.cValor) || '',
								cIndicadoresAdicionales: indicadoresAdicionales,
								cMunicipio: dataColonias?.cMunicipio,
								cEstado: dataColonias?.cEstado
							}
						);
					}
				} else {
					showError(response?.Result?.error);
					setLoading(false);
				}
			}).catch((error) => {
				console.error(error, 'error');
				setLoading(false);
				showError(errors.errorForm);
			});
		}

	};

	const handleOpenDomicilio = (reset?: boolean) => {
		setOpenDomicilio(true);
		setDireccionPredeterminada(dataDirecciones ? dataDirecciones.length === 0 : false);
		setDisabledDireccionPredeterminada(dataDirecciones ? dataDirecciones.length === 0 : false);
		if(reset){
			resetDireccion();
			setCodigoPostal('');
			setNDireccion(null);
		}
	};

	return {
		dataDirecciones,
		handleGoToMain,
		nDireccion,
		setNDireccion,
		nuevaDireccion: {
			codigoPostal,
			handleDataColonias,
			handleCodigoPostal,
			colonia,
			handleColonia,
			dataColonias,
			calle,
			handleCalle,
			numeroExterior,
			handleNumeroExterior,
			numeroInterior,
			handleNumeroInterior,
			entreCalle,
			handleEntreCalle,
			tipoDomicilio,
			handleTipoDomicilio,
			indicadoresAdicionales,
			handleIndicacionesAdicionales,
			dataTipoDomicilios,
			errorCodigoPostal,
			erroCalle,
			errorNumeroExt,
			errorNumeroInt,
			errorEntreCalle,
			errorIndic,
			errorGuardarDireccion,
			loadingColonias,
			handleGuardarDireccion: nDireccion ? handleEditarDireccion : handleGuardarDireccion,
			loading,
			direccionPredeterminada,
			handleDireccionPredeterminada,
			disabledDireccionPredeterminada,
		},
		direccionHooks: {
			openDomicilio,
			handleOpenDomicilio,
			handleCloseDomicilio,
			activePosition: activePosition,
			handleNext: handleNextWizard,
			handleDomicilio: handleDomicilio,
			handleBack: handleBackDireccionesHelper,
			domicilioSelected,
			handleDomicilioId,
		},
		deleteDireccion: {
			openDeleteDireccion,
			hadleOpenDeleteDireccion,
			hadleCloseDeleteDireccion,
			handleDeleteDireccion,
		},
		/* direccionPredeterminada: {
			openDireccionPredeterminada,
			hadleOpenDireccionPredeterminada,
			hadleCloseDireccionPredeterminada,
			handleDireccionPredeterminada,
		}, */
		editarDireccion: {
			handleOpenEditarDireccion,
			handleEditarDireccion
		}
	};
};

export default useDirecciones;