import React, { useContext, useEffect, useState } from 'react';
import { TIPO_PLATAFORMA_WEB, TIPO_STATUS_ORDEN } from '~/constants';
import { CatalogosTiposDatosProps, DataCarritoTypes, HeadersApiType, ICupon, INuevaOrdenModalError  } from '~/interfaces';
import { getUtcDate, helperPurchase } from '~/utils';
import useCupones from './useCupones';
import { errorNombresRegex, firstLetterUpperCase, handleHelperValidRegex, handleValidEmail, handleValidPhone } from '~/utils/validations';
import errors from '~/constants/errors';
import { GeneralLayoutContext } from '~/context/generalLayoutContext';
import { CheckoutContext } from '~/context/checkoutContext';
import moment from 'moment';
import useDialog from './useDialog';
import useDetalleProductoModal from './useDetalleProductoModal';

const useCheckout = (headersApi: HeadersApiType) => {
	const apiUrl = import.meta.env.VITE_API_URL;
	const {
		setshowTopbar,
		setNuevaOrdenModalOpen,
		setNuevaOrdenModalError,
		navigate,
		xs
	} = useContext(GeneralLayoutContext);
		//--- Cupon

	const {
		openCuponesModal,
		handleOpenCuponesModal,
		handleCloseCuponesModal,
		handleCurrentCupon,
		currentCupon,
		setCurrentCupon,
		cupon,
		handleCupon,
		addCupon,
		handleAddCupon,
		handleCancelAddCupon,
		cuponError,
		activePositionCupon,
		handleCanjearCupon,
		handleConsultaCupones,
		handleNextCupon,
		handleBackCupon,
		loadingCupon,
		loadingConsultaCupones,
		dataCupones,
		userInfo
	} = useCupones();

	const {
		nivelCheckoutId,
		handleConfig
	} = useContext(CheckoutContext);

	const {
		openDetalleProducto,
		productoId,
		handleOpenDetalleProductoModal,
		handleProductoId,
		handleOpenDetalleProducto,
		handleClick,
		handleDataCarrito,
		detalleEnvio,
		dataCarrito,
		loadingCheckoutCarrito,
		handleModificaCarrito,
		carritoLoading,
		nivelMainId,
		handleNivelMainId,
		handleOrdenar,
		carritoItem,
	} = useDetalleProductoModal(true, currentCupon?.nCupon);

	const {
		cardFormData,
		total,
		setMpData,
		settotal,
		email,
		setEmail,
		mpData,
		handleCardFormData,
		guardarTarjetaCheck,
		handleGuardarTarjetaCheck,
		handleBody3D,
		body3D
	} = useContext(CheckoutContext);

	// Recibe las tipos entregas (Domicilio o Recolección)
	const [dataTiposEntregas, setDataTiposEntregas] = useState<CatalogosTiposDatosProps[] | undefined>(undefined);

	useEffect(() => {
		
		if (dataTiposEntregas === undefined) {

			fetch(`${apiUrl}/APPWEB/ObtenerCatalogosTiposDatos?cLlave=APPWEBCAT_Tipo_Entrega`, {
				method: 'GET',
			}).then((res) => res.json()).then((response) => {

				if (response?.Result[0]) {
					const helper = response.Result[0];
					setDataTiposEntregas(helper);
				}
			}).catch((error) => {
				console.error(error, 'error');
			});
		}
		
	}, []);

	useEffect(() => {
		if(openCuponesModal) {
			handleConsultaCupones();
		} else if(currentCupon?.nCupon){
			handleDataCarrito(currentCupon.nCupon);
		}
	}, [openCuponesModal]);


	const [checkoutErrors, setCheckoutErrors] = useState({
		name: false,
		cel: false,
		email: false,
		domicilio: false,
		fechaYhora: false
	});

	// numeroCelular
	const [numeroCelular, setNumeroCelular] = useState('');
	const handleNumeroCelular = (e: any) => {
		setCheckoutErrors(previousState => {
			const copy = {...previousState};
			copy.cel = false;
			return copy;
		});
		setNumeroCelular(e);
	};

	const numeroCelularCheck = handleValidPhone(numeroCelular);
	
	// nombres
	const [nombres, setNombres] = useState('');
	const handleNombres = (e: React.ChangeEvent < HTMLInputElement > ) => {
		setCheckoutErrors(previousState => {
			const copy = {...previousState};
			copy.name = false;
			return copy;
		});
		setNombres(e.target.value);
	};

	// email
	const handleEmail = (e: React.ChangeEvent < HTMLInputElement > ) => {
		setEmail(e.target.value);
		setCheckoutErrors(previousState => {
			const copy = {...previousState};
			copy.email = false;
			return copy;
		});
	};
		
	const emailCheck = handleValidEmail(email);

	const nombresCheck = handleHelperValidRegex(nombres, errorNombresRegex, errors.nombresError);

	useEffect(() => {
		if(!userInfo?.invitado){
			setNombres(`${userInfo?.cNombre} ${userInfo?.cApellido_Paterno} ${userInfo?.cApellido_Materno}`);
			setNumeroCelular(userInfo?.cNumero_Celular || '');
			setEmail(userInfo?.cCorreo || '');
		}
	}, [userInfo]);


	const checkErrors = nombres === '' || nombresCheck.error || !numeroCelular || numeroCelularCheck.error || ((!(detalleEnvio?.nDireccion) && !(detalleEnvio?.nSucursal)) || (!(detalleEnvio?.cValorHoraAgendada) || !(detalleEnvio?.dFechaAgendada))) || email === '' || emailCheck.error;
	
	const handleCheck = () => {
		setCheckoutErrors({
			name: nombres === '' || nombresCheck.error,
			email: email === '' || emailCheck.error,
			cel: !numeroCelular || numeroCelularCheck.error,
			domicilio: !(detalleEnvio?.nDireccion) && !(detalleEnvio?.nSucursal),
			fechaYhora: !(detalleEnvio?.cValorHoraAgendada) || !(detalleEnvio?.dFechaAgendada)
		});
	};
	const tipoCheckout = import.meta.env.VITE_TIPO_CHECKOUT;

	useEffect(() => {
		if(checkoutErrors?.domicilio || checkoutErrors?.fechaYhora){
			handleCheck();
		}
	}, [detalleEnvio]);

	// Make it possible

	const [gralLoading, setGralLoading] = useState(false); // cuando se le da tiempo al usuario para cancelar la orden
	const [finishingLoading, setFinishingLoading] = useState(false); // cuando ya está cargando la api de pagar
	const [showMercadoPago, setShowMercadoPago] = useState(false); // muestra el formulario de pago

	const [openMissingFields, setOpenMissingFields] = useState(false);

	const handleCloseMissingFields = () => {
		setOpenMissingFields(false);
	};

	const handleShowMercadoPago = () => {
		if (!activeCheckout) {
			setActiveCheckout(true);
		} else 
			if(checkErrors && activeCheckout){
				handleCheck();
				setOpenMissingFields(true);
			
			} else {
				setShowMercadoPago(true);
			}
	};

	const handleHideMercadoPago = () => {
		setShowMercadoPago(false);
		if(tipoCheckout === '1'){
			const scriptElement = document.getElementById('mercadoPagoScript');
			if(scriptElement){
				scriptElement?.parentNode?.removeChild(scriptElement);
			}
		}
	};




	const [activeCheckout, setActiveCheckout] = useState(false);
	const handleCancelarPedido = () => {
		if(!finishingLoading){
			setGralLoading(false);
			setFinishingLoading(false);
			setShowMercadoPago(false);
		}
	};

	function doChallenge(payment: any) {
		const {
			status,
			status_detail,
			three_ds_info: { creq, external_resource_url },
		} = payment;

		localStorage.setItem('paymentId', payment.id);
		
		handleBody3D({
			additionalInfo: {
				creq,
				externalResourceURL: external_resource_url,
			}, 
			paymentId: payment.id,
		});
		setTimeout(()=>{
			try {
			
				if (status === 'pending' && status_detail === 'pending_challenge') {
					const iframe = document.createElement('iframe');
					iframe.name = 'myframe';
					iframe.id = 'myframe';
					iframe.height = '500px';
					iframe.width = xs ? '490px' : '100%';
					document.body.appendChild(iframe);

					const idocument = iframe?.contentWindow?.document;
					
					if(idocument ) {
						const containerDiv = document.getElementById('containerDiv'); // Reemplaza 'containerDiv' con el ID de tu elemento div
	
						const myform = idocument.createElement('form');
						myform.name = 'myform';
						myform.setAttribute('target', 'myframe');
						myform.setAttribute('method', 'post');
						myform.setAttribute('action', external_resource_url);
	
						const hiddenField = idocument.createElement('input');
						hiddenField.setAttribute('type', 'hidden');
						hiddenField.setAttribute('name', 'creq');
						hiddenField.setAttribute('value', creq);
						myform.appendChild(hiddenField);
						iframe.appendChild(myform);
						containerDiv?.appendChild(iframe); // Agrega el iframe al elemento div de tu aplicación
	
						myform.submit();
					}
					
				}
			} catch (error) {
				console.error(error);
			}
		}, 100);
	
	}

	const [loadingInit, setLoadingInit] = useState(false);

	async function init() {
		setLoadingInit(true);
		const id = localStorage.getItem('paymentId');

		fetch(`${apiUrl}/APPWEB/ConsultaEstatusPagoMP?id=${id}`, {
			method: 'GET',
			headers: headersApi,
		}).then(res => res.json()).then((res) => {

			if(!res?.Result?.body?.pending) {
				carrito && helperPurchase(carrito, cardFormData?.token || '', selectedCupon?.cNombre);
				setNuevaOrdenModalError(res?.Result?.body?.error ? res?.Result?.body : null);
				navigate('/');
				handleBody3D(null);
				setshowTopbar(true);
				setNuevaOrdenModalOpen(true);
			}
			
		}).catch(() => {
			setNuevaOrdenModalError({
				error: errors.errorForm,
				nFolio: 0,
				nNivelTienda: 0,
				subError: ''
			});
			navigate('/');
			handleBody3D(null);
			setshowTopbar(true);
			setNuevaOrdenModalOpen(true);
		});


		

	}
	useEffect(() => {
		window?.addEventListener('message', (e) => {
			if (e.data.status === 'COMPLETE') {
				init();
			}
		});
	}, []);
	
	const handlePagar = () => { //
		if(cardFormData){
			const {
				paymentMethodId: payment_method_id,
				issuerId: issuer_id,
				cardholderEmail,
				amount,
				token,
				installments,
				identificationNumber,
				identificationType,
			} = cardFormData;
			
			setshowTopbar(false);
			setFinishingLoading(true);

			const pago = {
				token,
				issuer_id,
				payment_method_id,
				transaction_amount: Number(amount),
				installments: installments ? Number(installments) : 1,
				description: '',
				payer: tipoCheckout === '2' ? {
					email: cardholderEmail || undefined,
					type: identificationType || undefined,
					id: identificationNumber || undefined,
				} : {
					email: cardholderEmail || undefined,
					identification: {
						type: identificationType || undefined,
						number: identificationNumber || undefined,
					},
				},
			};

			const orden = {
				nNivelTienda: nivelCheckoutId,
				nTipoPlataforma: TIPO_PLATAFORMA_WEB,
				nRegion: detalleEnvio?.nMunicipio,
				nSucursal: detalleEnvio?.nSucursal,
				dFechaEntrega: detalleEnvio?.dFechaAgendada ? (`${getUtcDate(detalleEnvio?.dFechaAgendada, 'YYYY-MM-DD')} ${detalleEnvio?.cHoraAgendada}:00`) : (moment().format('YYYY-MM-DD hh:mm:ss')),
				nTipoEntrega: detalleEnvio?.nTipoEntrega,
				nEstatus: TIPO_STATUS_ORDEN,
				nTipoTarifaDomicilio: 1,
				nCostoDomicilio: carrito?.nPrecioEnvio,
				nCupon: currentCupon?.nCupon, 
				nTotal: carrito?.nTotal,
				nColonia: detalleEnvio?.nColonia,
				cEmailUsuario: email,
				cCalle: detalleEnvio?.cCalle,
				cNumeroExterior: detalleEnvio?.cNumeroExterior,
				cNumeroInterior: detalleEnvio?.cNumeroInterior,
				cEntreCalle: detalleEnvio?.cEntreCalle,
				nTipoDomicilio: detalleEnvio?.nTipoDomicilio,
				cReferencias: detalleEnvio?.cReferencias,
				nNumeroCelular: numeroCelular,
				cNombreRecibe: nombres,
				bGuardarTarjeta: !userInfo?.invitado && guardarTarjetaCheck
			};

			const body = {
				pago,
				orden
			};

	
			fetch(`${apiUrl}/APPWEB/RegistrarPago`, {
				method: 'POST',
				headers: headersApi,
				body: JSON.stringify(body),
			}).then((res) => res.json()).then((response) => {

				if(response?.Result?.error || response?.Result?.done === false){
					if(response?.Result?.error){
						const dataError: INuevaOrdenModalError  = response?.Result;
						setNuevaOrdenModalError(dataError);
						if(dataError.nFolio){
							navigate('/');
							handleBody3D(null);
						}
					} else {
						setNuevaOrdenModalError({
							error: errors.errorForm,
							nFolio: 0,
							nNivelTienda: 0,
							subError: ''
						});
					}
					
					handleCancelarPedido();
					setshowTopbar(true);
					setNuevaOrdenModalOpen(true);
				} else if (response?.Result?.cardchallenge) {
					const body = response?.Result?.body;
					localStorage.setItem('paymentId', body.id);
					doChallenge(body);
				} else {
					// Pago procesado correctamente
					carrito && helperPurchase(carrito, cardFormData.token, selectedCupon?.cNombre);
					setNuevaOrdenModalError(null);
					navigate('/');
					handleBody3D(null);
					setshowTopbar(true);
					setNuevaOrdenModalOpen(true);
				}
			}).catch((error) => {
				console.error(error, 'error');
			});
			
		}
		
	};

	useEffect(() => {
		if(!activeCheckout){
			setActiveCheckout(true);
		} else 
			if(checkErrors && activeCheckout){
				handleCheck();
			
			} else
				if(cardFormData) {	
					const {
						cardholderEmail: email,
						identificationNumber,
						identificationType,
					} = cardFormData;
			
	
					if(email === '' && (identificationNumber === '' || identificationType === '')){
						setGralLoading(false);
						setFinishingLoading(false);

	
					} else{
						setGralLoading(true);
						window?.scrollTo({
							top: 0,
							behavior: 'smooth' // Desplazamiento suave
						});
						if(tipoCheckout === '1'){
							const scriptElement = document.getElementById('mercadoPagoScript');
							if(scriptElement){
								scriptElement?.parentNode?.removeChild(scriptElement);
							}
						}
					}
				
				}
	}, [cardFormData]);



	const carrito: DataCarritoTypes | undefined = dataCarrito?.[0];

	useEffect(() => {
		if(carrito && settotal){
			settotal(carrito.nTotal);
		}
	}, [carrito?.nTotal]);

	const [selectedCupon, setSelectedCupon] = useState<ICupon | null>(null);
	
	useEffect(() => {
		if(carrito?.nCupon && setSelectedCupon){
			const newCupon = {
				cNombre: carrito.cNombreCupon,
				nCupon: carrito.nCupon,
				nDescuentoCupon: carrito.nDescuentoCupon,
				cErrorCupon: carrito.cErrorCupon
			};
			setSelectedCupon(newCupon);

			setCurrentCupon(newCupon);
		} else if(carrito?.nCupon === 0) {
			setSelectedCupon(null);

			setCurrentCupon(null);
		}
	}, [carrito, openCuponesModal]);

	const [loadingMp, setLoadingMp] = useState(true);
	
	useEffect(() => {
		if(headersApi.Authorization){
			fetch(`${apiUrl}/APPWEB/ConsultaMP`, {
				method: 'GET',
				headers: headersApi
			}).then((res) => res.json()).then((response) => {
				if(response?.Result?.MP){
					setMpData(response?.Result?.MP);
				}
				setLoadingMp(false);

			}).catch((error) => {
				console.error(error, 'error');
			});
		}
			
	}, [headersApi.Authorization]);


	useEffect(() => {
		if(headersApi.Authorization){
			const body = {
				nNivelTienda: nivelCheckoutId,
			};
			fetch(`${apiUrl}/APPWEB/ObtenerMercadoPagoMsiConfig`, {
				method: 'POST',
				headers: headersApi,
				body: JSON.stringify(body),
			}).then((res) => res.json()).then((response) => {
				handleConfig(response?.Result?.config || {});
			}).catch((error) => {
				console.error(error, 'error');
			});
		}
			
	}, [headersApi.Authorization]);

	const {
		open: showCarrito,
		handleOpen: handleShowCarrito,
	} = useDialog();

	const [showDatosContacto, setShowDatosContacto] = useState(!!(userInfo?.invitado));

	const [editDatosContacto, setEditDatosContacto] = useState(!!(userInfo?.invitado));

	const handleEditDatosContacto = () => {
		setEditDatosContacto(true);
		setShowDatosContacto(true);
	};

	const handleDatosContacto = () => {
		setShowDatosContacto(!showDatosContacto);
		if(showDatosContacto){
			setTimeout(()=>setEditDatosContacto(false), 300);
		}
	};

	const [openSobrePedidoModal, setOpenSobrePedidoModal] = useState(false);
	const [descripcionModal, setDescripcionModal] = useState<JSX.Element | JSX.Element[] | string>('');
	const handleOpenSobrePedidoModal = (handleContinue: (hadleDone: VoidFunction) => void) => {
		handleContinue(() => {
			setOpenSobrePedidoModal(true);
			const diaFuturo = detalleEnvio?.dFechaAgendada && getUtcDate(detalleEnvio?.dFechaAgendada, 'YYYY-MM-DD') !== moment().format('YYYY-MM-DD');
	
			setDescripcionModal(<>Tu pedido se entregará {diaFuturo ? <>el día <strong>{firstLetterUpperCase(getUtcDate(detalleEnvio?.dFechaAgendada || '', 'dddd'))} {getUtcDate(detalleEnvio?.dFechaAgendada || '', 'LL')}</strong></>: <strong>hoy</strong>} a las <strong>{detalleEnvio?.cHoraAgendada}</strong><br />{detalleEnvio?.cSucursal ? <>en sucursal <strong>{detalleEnvio?.cSucursal}</strong></> : <>en el domicilio <strong>{`${detalleEnvio?.cCalle || ''} ${detalleEnvio?.cNumeroExterior ? `#${detalleEnvio?.cNumeroExterior}` : ''} ${detalleEnvio?.cColonia ? `Col. ${detalleEnvio?.cColonia}` : ''}`}</strong></>} <br/><br /> ¿Deseas continuar? </>);
		});
	};

	const handleCloseSobrePedidoModal = () => {
		setOpenSobrePedidoModal(false);
		setDescripcionModal('');
	};

	const [loadingRemove, setLoadingRemove] = useState<number | null>(null);

	const handleloadingRemove = (value: number) => {
		setLoadingRemove(value);
	};

	const handleStoploadingRemove = () => {
		setLoadingRemove(null);
	};

	return {
		dataTiposEntregas,
		cupon,
		handleCupon,
		addCupon,
		handleAddCupon,
		handleCancelAddCupon,
		activePositionCupon,
		selectedCupon,
		handleNextCupon,
		handleBackCupon,
		handleCanjearCupon,
		userInfo,
		loadingCupon,
		loadingConsultaCupones,
		dataCupones,
		openMissingFields,
		handleCloseMissingFields,
		loadingMp,
		cuponError,
		openCuponesModal,
		handleOpenCuponesModal,
		handleCloseCuponesModal,
		handleCurrentCupon,
		currentCupon,
		handlePagar,
		numeroCelular,
		handleNumeroCelular,
		numeroCelularCheck,
		nombres,
		handleNombres,
		nombresCheck,
		checkoutErrors,
		gralLoading,
		finishingLoading,
		handleCancelarPedido,
		carrito,
		handleCardFormData,
		nivelCheckoutId,
		loadingCheckoutCarrito,
		tipoCheckout,
		total,
		showMercadoPago,
		handleShowMercadoPago,
		mpData,
		handleHideMercadoPago,
		handleEmail,
		showCarrito,
		handleShowCarrito,
		showDatosContacto,
		handleDatosContacto,
		editDatosContacto,
		handleEditDatosContacto,
		email,
		openSobrePedidoModal,
		emailCheck,
		handleOpenSobrePedidoModal,
		handleCloseSobrePedidoModal,
		loadingRemove,
		handleloadingRemove,
		handleStoploadingRemove,
		guardarTarjetaCheck,
		handleGuardarTarjetaCheck,
		openDetalleProducto,
		productoId,
		handleOpenDetalleProductoModal,
		handleProductoId,
		handleOpenDetalleProducto,
		handleClick,
		handleModificaCarrito,
		carritoLoading,
		nivelMainId,
		carritoItem,
		handleNivelMainId,
		handleOrdenar,
		body3D,
		loadingInit,
		descripcionModal
	};
};

export default useCheckout;