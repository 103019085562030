import { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { CookiesContext } from '~/context/cookiesContext';
import { GeneralLayoutContext } from '~/context/generalLayoutContext';
import { JCategorias, ObtenerBusquedasPopularesTypes } from '~/interfaces';

const useTextFieldSearchMain = (handleCloseSearch?: VoidFunction, nivelesTiendaId?: number) => {
	const { handleDoneInitialLoading, headersApi, isUserActive } = useContext(CookiesContext);
	const {
		mainValue,
		setMainValue,
	} = useContext(GeneralLayoutContext);
	useEffect(() => {
		handleDoneInitialLoading();
	}, []);
	const [activeTab, setActiveTab] = useState<ObtenerBusquedasPopularesTypes>({});
	const [isShowing, setIsShowing] = useState(true);

	const handleActiveTab = (value: ObtenerBusquedasPopularesTypes) => {
		setActiveTab(value);
	};

	const handleIsShowing = () => {
		setIsShowing(!isShowing);
	};

	// mainValue
	const handlemainValue = (e: React.ChangeEvent < HTMLInputElement > ) => {
		setMainValue(e.target.value);
	};
	const navigate = useNavigate();

	const handleBuscarInput = () => {
		navigate(`/buscador/${encodeURIComponent(mainValue)}${nivelesTiendaId ? `/${nivelesTiendaId}` : ''}`);
		if(handleCloseSearch){
			handleCloseSearch();

		}
	};
	
	function handleKeyPress(event: React.KeyboardEvent<HTMLInputElement>) {
		const regex = /^[A-ZÀ-ú #0-9]+$/i; // Expresión regular que permite letras, números, espacios en blanco y guiones
		const char = String.fromCharCode(event.charCode);
		if (!regex.test(char)) { // Verificar si el carácter no es válido
			event.preventDefault(); // Evitar que se Ingresa el carácter no válido
		}
	}
	
	const apiUrl = import.meta.env.VITE_API_URL;
	const [busquedasPopulares, setBusquedasPopulares] = useState<ObtenerBusquedasPopularesTypes[]>([]);

	useEffect(() => {
		// para obtener el catálogo de géneros
		if (isUserActive) {
			fetch(`${apiUrl}/APPWEB/ObtenerBusquedasPopulares`, {
				method: 'GET',
				headers: headersApi
			}).then((res) => res.json()).then((response) => {
				if (response?.Result?.dataBusquedasPopulares) {
					setBusquedasPopulares(response?.Result?.dataBusquedasPopulares);
					setActiveTab(response?.Result?.dataBusquedasPopulares[0] || {});
				}
			}).catch((error) => {
				console.error(error, 'error');
			});
		}
	}, [isUserActive, headersApi.Authorization]);

	const handleBusquedaPopular = (cDescripcion: string) => {
		navigate(`/buscador/${encodeURIComponent(cDescripcion)}`);
		setMainValue(cDescripcion);
		if(handleCloseSearch){
			handleCloseSearch();
		}
	};

	const handleGoToCategoria = ( item: JCategorias, nNivelTienda?: number,) => {
		if(nNivelTienda){
			navigate(`/tienda/${nNivelTienda}/categoria/${item.nCategoria}`);
		}
		if(handleCloseSearch){
			handleCloseSearch();
		}
	};

	

	return {
		activeTab,
		handleActiveTab,
		isShowing,
		handleIsShowing,
		mainValue,
		handlemainValue,
		handleKeyPress,
		busquedasPopulares,
		handleBuscarInput,
		handleBusquedaPopular,
		apiUrl,
		handleGoToCategoria
	};
};

export default useTextFieldSearchMain;