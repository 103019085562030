export const ERRORES_FRECUENTES = {
    ef0: 'Intentalo más tarde',
    ef1: 'Inicio de sesión fallido',
    ef2: 'Error OTP',
    ef3: 'El correo no pudo ser enviado',
    ef4: 'Error general',
    ef5: 'Error al actualizar usuario',
    ef6: 'Sesión inválida',
    ef7: 'Autenticación Fallida',
    ef8: 'Error con el correo electrónico.',
    ef9: 'Usuario no encontrado',
    ef10: 'No se pudo redimir el ticket',
}