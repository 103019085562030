import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

import { useTitle } from './hooks';
import { GeneralLayout, GeneralLoading } from 'components';

import CookiesContext from './context/cookiesContext';
import AlertToastContext from './context/alertToastContext';
import ScrollProvider from './context/scrollContext';
import GeneralLayoutContext from './context/generalLayoutContext';
import CheckoutProvider from './context/checkoutContext';

const DinamicasProvider = lazy(() => import('./context/dinamicasContext'));
const Main = lazy(() => import('./views/main'));
const Auth = lazy(() => import('./views/auth'));
const ReestablecerContrasenia = lazy(() => import('./views/reestablecerContrasenia'));
const Tienda = lazy(() => import('./views/tienda'));
const Checkout = lazy(() => import('./views/checkout'));
const MisDirecciones = lazy(() => import('./views/misDirecciones'));
const AjustesDeCuenta = lazy(() => import('./views/ajustesDeCuenta'));
const Sucursales = lazy(() => import('./views/sucursales'));
const TiendaCategorias = lazy(() => import('./views/tiendaCategorias'));
const Favoritos = lazy(() => import('./views/favoritos'));
const Ayuda = lazy(() => import('./views/ayuda'));
const Dinamicas = lazy(() => import('./views/dinamicas'));
const PageNotFound = lazy(() => import('./components/PageNotFound'));
const HistorialDePedidos = lazy(() => import('./views/historialDePedidos'));
const DetallePedido = lazy(() => import('./views/detallePedido'));
const TemasDeAyuda = lazy(() => import('./views/temasDeAyuda'));
const TemasDeAyudaSubtema = lazy(() => import('./views/temasDeAyudaSubtema'));
const MercadoPagoCheckout = lazy(() => import('./views/mercadoPagoCheckout'));
const CuponesPromociones = lazy(() => import('./views/cuponesPromociones'));
const Buscador = lazy(() => import('./views/buscador'));
const Trabajo = lazy(() => import('./views/trabajo'));
const Dinamica = lazy(() => import('./views/dinamica'));
const Catalogos = lazy(() => import('./views/catalogos'));
const CentroPrivacidad = lazy(() => import('./views/privacidad'));

const RouterMain: React.FC = () => {
	const tipoCheckout = import.meta.env.VITE_TIPO_CHECKOUT;

	useTitle();

	return (
		<Router>
			<ScrollProvider>
				<CookiesContext>
					<CheckoutProvider>
						<AlertToastContext>
							<GeneralLayoutContext>
								<GeneralLayout>
									<Suspense fallback={<GeneralLoading showGeneralLoading />}>
										<Routes>
											<Route path="/" element={<Main />} />

											{/* Autenticacion */}
											<Route path="/crear-cuenta" element={<Navigate to="/autenticate" replace={true} />} />
											<Route path="/inicia-sesion" element={<Navigate to="/autenticate" replace={true} />} />
											<Route path="/recuperar-contrasenia" element={<Navigate to="/autenticate" replace={true} />} />
											<Route path="/autenticate" element={<Auth />} />

											<Route path="/reestablecer-contrasenia/:token" element={<ReestablecerContrasenia />} />

											{/* tienda */}											
											<Route path="/tienda/:nNivelTienda" element={<TiendaCategorias />} />
											<Route path="/tienda/:nNivelTienda/categoria/:categoriaId" element={<Tienda />} />
											<Route path="/tienda/:nNivelTienda/categoria/:categoriaId/:nVProducto" element={<Tienda />} />

											{/* checkout */}
											<Route path="/checkout" element={<Navigate to="/" />} />
											<Route path="/checkout/:nNivelTienda" element={tipoCheckout === '1' ? <Checkout /> : <MercadoPagoCheckout />} />

											{/* Perfil */}
											<Route path="/mis-direcciones" element={<MisDirecciones />} />
											<Route path="/mi-usuario" element={<AjustesDeCuenta />} />

											{/* Side bar */}
											<Route path="/sucursales" element={<Sucursales />} />
											<Route path="/sucursales/:tipoMunicipio" element={<Sucursales />} />

											{/* <Route path="/metodos-de-pago" element={<Cartera/>}/> */}

											<Route path="/favoritos" element={<Favoritos />} />

											{/* Pedidos */}
											<Route path="/historial-de-pedidos" element={<HistorialDePedidos />} />
											<Route path="/historial-de-pedidos/:nFolio" element={<DetallePedido />} />
											<Route path="/ver-detalles-pedido/:nFolio" element={<DetallePedido main />} />
											<Route path="/ver-detalles-pedido" element={<Navigate to="/" />} />

											{/* Ayuda */}
											<Route path="/ayuda" element={<Ayuda />} />
											<Route path="/temas-de-ayuda/:nTema" element={<TemasDeAyuda />} />
											<Route path="/temas-de-ayuda/:nTema/:nSubtema" element={<TemasDeAyudaSubtema />} />

											<Route path="/cupones-promociones" element={<CuponesPromociones />} />
											<Route path="/buscador/:value" element={<Buscador />} />
											<Route path="/buscador/:value/:nNivelTienda" element={<Buscador />} />
											<Route path="/buscador" element={<Navigate to="/" />} />
											<Route path="/trabajo" element={<Trabajo />} />

											<Route path="/dinamicas" element={<DinamicasProvider />}>
												<Route index element={<Dinamicas />} />
												<Route path=":nDinamica" element={<Dinamica />} />
											</Route>

											<Route path="/Catalogos/:id" element={<Catalogos />} />

											{/* Centro de Privacidad */}
											<Route path='/Privacidad' element={<CentroPrivacidad />} />

											<Route path="*" element={<PageNotFound />} />
										</Routes>
									</Suspense>
								</GeneralLayout>
							</GeneralLayoutContext>
						</AlertToastContext>
					</CheckoutProvider>
				</CookiesContext>
			</ScrollProvider>
		</Router>
	);
};

export default RouterMain;