import React from 'react';
import IconButton from '../IconButton';
import { TextFieldContainer, TextFieldInput, TextFieldContainerInput, IconContainer } from './styled';
import { useDialog } from '~/hooks';
import { EyeIcon, EyeOffIcon } from '~/assets/icons';
import { TextFieldLabel } from '../TextField/styled';
import Typography from '../Typography';
import Grid from '../Grid';
type Props = {
	value?: string;
	padding?: string;
	label?: string;
	autoCompleteOff?: boolean;
	check?: boolean;
	helperText?: string;
	error?: boolean;
	children?: JSX.Element | JSX.Element[] | string;
	placeholder?: string;
	onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
	onChange: React.ChangeEventHandler<HTMLInputElement> | undefined;
};
const PaswordTextFiled: React.FC<Props> = ({ padding, value, onChange, label, error, check, onKeyDown, placeholder, helperText, children, autoCompleteOff }: Props) => {
	const { open, handleOpen } = useDialog();
	return (
		<TextFieldContainer padding={padding}>
			<Grid container>
				<TextFieldLabel>{label || 'Contraseña'}</TextFieldLabel>
				<Grid alignItems="center" flex item container justifyContent="flex-end">
					{children}
				</Grid>
			</Grid>
			<TextFieldContainerInput check={check} error={error}>
				<TextFieldInput
					autoComplete={autoCompleteOff ? 'new-password' : undefined}
					placeholder={placeholder}
					onKeyDown={(e) => {
						if (e.key === 'Enter' && onKeyDown) {
							onKeyDown(e);
						}
					}}
					type={open ? 'text' : 'password'}
					onChange={onChange}
					value={value}
				/>
				<IconContainer>
					<IconButton onClick={handleOpen}>{open ? <EyeOffIcon color="grey"></EyeOffIcon> : <EyeIcon color="grey"></EyeIcon>}</IconButton>
				</IconContainer>
			</TextFieldContainerInput>
			<Grid textAlign="justify">
				<Typography padding="5px 0 0 0" variant="regular" color={error ? 'red' : 'grey'}>
					{helperText}
				</Typography>
			</Grid>
		</TextFieldContainer>
	);
};
export default PaswordTextFiled;
